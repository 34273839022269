<template>
  <main id="rDom">
    <center>
      <div id="rDomBox">
        <div class="d-flex justify-content-center" id="rDomMenu">
          <router-link to="/client/domain-contact-details-bf">
            <span id="backBtn" class="btn">
              <i class="bi bi-arrow-left"></i>
            </span>
          </router-link>
          <!--  -->
          <!--  -->
          <span id="downloadPDF" @click="validate()">
            <i class="bi bi-file-earmark-pdf-fill text-danger me-1"></i>
            <a>Télécharger</a>
          </span>
          <!--  -->
          <!--  -->
          <router-link to="/client/domainBfDocs">
            <span id="continueBtn" class="btn">
              <i class="bi bi-arrow-right"></i>
            </span>
          </router-link>
        </div>

        <div id="process">
          <b>Procédure*: </b> <br />
          Vous devez télécharger ce document, l'imprimer, signer et cacheter
          (avec la mention lu et approuvé), suite à quoi vous pouvez continuer
          en cliquant sur la flèche de droite.
        </div>
      </div>

      <div id="rDomain">
        <section class="onePage">
          <br />
          <div id="headOfDoc" class="mb-3 d-flex justify-content-between">
            <img src="../../assets/images/arcep.png" class="rLogo" height="104" />

            <img src="../../assets/images/abdi.png" class="rLogo" height="104" />

            <p class="">
              <strong class="fs-5">BURKINA FASO</strong>
              <br />
              <i class="fs-6">UNITE - PROGRES - JUSTICE</i>
            </p>
          </div>

          <center class="mb-1">
            <div class="contact">
              Contacts ABDI: 01 BP 6251 Ouagadougou 01 | Tél: +226 25 33 25 25 |
              <a href="https://www.abdi.bf">www.abdi.bf</a>
            </div>
            <div class="contact">
              Contacts ARCEP: 01 BP 6437 Ouagadougou 01 | Tél: +226 25 37 53 60,
              +226 25 37 53 61/62 |
              <a href="https://www.abdi.bf">www.arcep.bf</a>
            </div>
            <div class="contact">
              Courriers électroniques pour soumettre le présent formulaire:
              <a>contact.cctldbf@abdi.bf,</a>
              <a>supportdns@arcep.bf</a>
            </div>
          </center>

          <center>
            <div class="rDomainTitle">
              <h4 style="font-weight: 800 !important">
                GESTION DES NOMS DE DOMAINE (ccTLD .BF)
              </h4>
              <h5 style="font-size: 21px">
                Formulaire d'enregistrement, de modification ou de suppression
                de domaine Internet dans la zone "BF"
              </h5>
              <div class="contact">
                Une convention de délégation de gestion du ".bf", lie l'ARCEP à
                l'ABDI, et autorise cette dernière à traiter le demandes de noms
                de domaine ".bf" pour le compte de l'ARCEP. Ce formulaire
                contient quatre pages à retourner à l'ABDI. Il est recommander
                de compléter le formulaire à l'ordinateur avant de l'imprimer.
                Les champs avec un astérisque (*) sont obligatoires.
              </div>
            </div>
          </center>
          <!--  -->
          <!--  -->
          <!--  -->
          <div class="text-start">
            <div class="mt-2">
              <b class="fs-6">1 - DEMANDEUR - ORGANISME DEMANDEUR (REGISTRANT)</b>
            </div>

            <div class="dataBox float-start">
              <div class="float-start">
                <a class="float-start me-2 text-dark">NOM/Prénoms ou Organisme*:
                </a>
                <div class="float-start d-inline-block response" style="width: 490px !important">
                  {{
                    data.natureRegister == "PERSON"
                      ? data.Fname1
                      : data.organism1
                  }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Sigle (éventuel): </a>
                <div class="float-start d-inline-block response" style="width: 200px !important">
                  {{ data.Sigle }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Profession ou Activité*:
                </a>
                <div class="float-start d-inline-block response" style="width: 234px !important">
                  {{ data.rProfession }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Rue/Av./Secteur*: </a>
                <div class="float-start d-inline-block response" style="width: 340px !important">
                  {{ data.Address1 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Ville*: </a>
                <div class="float-start d-inline-block response" style="width: 171px !important">
                  {{ data.City1 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Boîte et Code postal(e):
                </a>
                <div class="float-start d-inline-block response" style="width: 308px !important">
                  {{ data.PostalBox1 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Pays*: </a>
                <div class="float-start d-inline-block response" style="width: 174px !important">
                  {{ data.CountryName1 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Téléphone(s)*: </a>
                <div class="float-start d-inline-block response" style="width: 360px !important">
                  {{ data.Tel1 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Fax: </a>
                <div class="float-start d-inline-block response" style="width: 176.5px !important">
                  {{ data.Fax1 }}
                </div>
              </div>

              <div class="float-start mt-1 mb-1">
                <a class="float-start me-2 text-dark">Mail*: </a>
                <div class="float-start d-inline-block response" style="width: 280px !important">
                  {{ data.Email1 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Forme Juridique*:
                </a>
                <div class="float-start d-inline-block response" style="width: 233px !important">
                  {{ data.rJuridic }}
                </div>
              </div>

              <div class="text-center">
                Exemples de forme juridique : société (SARL, SA...), université,
                organisme public, association...
              </div>
            </div>

            <!--  -->
            <!--  -->
            <!--  -->
            <div class="float-start mt-2">
              <b class="fs-6">2 - NOM DU DOMAINE</b>
            </div>

            <div class="dataBox float-start">
              <div class="float-start">
                <div class="float-start mb-2">
                  L'ARCEP est le Registre officiel en charge de la gestion des
                  noms de domaine de premier niveau ".bf". L'ARCEP à travers
                  l'ABDI, reste seul juge quant à l'acceptation ou non du om de
                  domaine demandé conformement au règles actuellement en vigueur
                  sur le plan national (Loi n°011-2010/AN...) et international
                  (règles de l'ICANN).
                </div>
                <a class="float-start me-2 text-dark">Nom du domaine*: </a>
                <div class="float-start d-inline-block response text-end" style="width: 500px !important">
                  {{ data.DomainOnly }}
                </div>
                <b class="float-start fw-normal mt-1 ms-1">.bf</b>
              </div>
            </div>

            <!--  -->
            <!--  -->
            <!--  -->
            <div class="float-start mt-2">
              <b class="fs-6">3 - NATURE DE LA DEMANDE</b>
            </div>

            <div class="dataBox float-start w-100">
              <div class="float-start">
                <div class="float-start mb-2 w-100">
                  Cocher la case correspondante à la demande*:
                </div>

                <div class="float-start d-inline-block checkresponse position-relative">
                  <span class="d-inline-block">
                    <i class="bi bi-x"></i>
                  </span>
                  <a class="text-dark">Création du domaine</a>
                </div>

                <div class="float-start d-inline-block checkresponse position-relative">
                  <span class="d-inline-block"></span>
                  <a class="text-dark">Modification</a>
                </div>

                <div class="float-start d-inline-block checkresponse position-relative">
                  <span class="d-inline-block"></span>
                  <a class="text-dark">Suppression</a>
                </div>
              </div>
            </div>

            <!--  -->
            <!--  -->
            <!--  -->
            <div class="float-start mt-2">
              <b class="fs-6">4 - DELEGATION DE GESTION</b>
            </div>

            <div class="dataBox float-start">
              <div class="float-start">
                <div class="float-start mb-2">
                  Si la gestion de votre domaine st confiée, ou a été confiée à
                  un prestataire de service, indiquez ci-dessous le nouveau et
                  eventuellement l'ancien prestataire auquel vous confié la
                  gestion de votre domaine ou des serveurs de noms. L'organisme
                  demandeur s'engage à prévenir le ou les prestataires concernés
                  avant de demander toutes modifications à l'ABDI.
                </div>

                <div class="float-start mt-1">
                  <a class="float-start me-2 text-dark">Prestataire: </a>
                  <div class="float-start d-inline-block response" style="width: 238px !important">
                    {{ data.OldPrest }}
                  </div>
                  <a class="float-start me-2 text-dark ms-1">Ancien prestataire:
                  </a>
                  <div class="float-start d-inline-block response" style="width: 238px !important">
                    {{ data.NewPrest }}
                  </div>
                </div>
              </div>
            </div>

            <div class="float-start text-center onePageFooter">
              <i style="font-size: 10px">Version 3.2 © ARCEP - Mars 2021</i>
              <a class="float-end text-dark" style="font-size: 12px">1</a>
            </div>
          </div>
        </section>

        <section class="onePage">
          <br />
          <br />
          <div class="text-start">
            <div class="mt-2">
              <b class="fs-6">5 - RESPONSABLE ADMINISTRATIF DU DOMAINE</b>
            </div>

            <div class="dataBox float-start">
              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Nom*: </a>
                <div class="float-start d-inline-block response" style="width: 200px !important">
                  {{ data.LastName3 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Prénoms*: </a>
                <div class="float-start d-inline-block response" style="width: 348px !important">
                  {{ data.FirstName3 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Structure*: </a>
                <div class="float-start d-inline-block response" style="width: 200px !important">
                  {{ data.rStructure3 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Service et fonction*:
                </a>
                <div class="float-start d-inline-block response" style="width: 274px !important">
                  {{ data.rServiceFunction3 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Rue/Av./Secteur*: </a>
                <div class="float-start d-inline-block response" style="width: 346px !important">
                  {{ data.Address3 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Fax: </a>
                <div class="float-start d-inline-block response" style="width: 173.5px !important">
                  {{ data.Fax3 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Boîte et Code postal(e):
                </a>
                <div class="float-start d-inline-block response" style="width: 160px !important">
                  {{ data.PostalBox3 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Ville*: </a>
                <div class="float-start d-inline-block response" style="width: 140px !important">
                  {{ data.City3 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Pays*: </a>
                <div class="float-start d-inline-block response" style="width: 140px !important">
                  {{ data.CountryName3 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Téléphone(s)*: </a>
                <div class="float-start d-inline-block response" style="width: 294px !important">
                  {{ data.Tel3 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Mail*: </a>
                <div class="float-start d-inline-block response" style="width: 235.5px !important">
                  {{ data.Email3 }}
                </div>
              </div>
            </div>

            <!--  -->
            <!--  -->
            <!--  -->
            <div class="float-start mt-2">
              <b class="fs-6">6 - ADRESSE DE FACTURATION</b>
            </div>

            <div class="dataBox float-start">
              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Adresse: </a>
                <div class="float-start d-inline-block response" style="width: 600px !important">
                  {{ data.Address4 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Boîte et Code postal(e):
                </a>
                <div class="float-start d-inline-block response" style="width: 160px !important">
                  {{ data.PostalBox4 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Ville*: </a>
                <div class="float-start d-inline-block response" style="width: 140px !important">
                  {{ data.City4 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Pays*: </a>
                <div class="float-start d-inline-block response" style="width: 140px !important">
                  {{ data.CountryName4 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Téléphone(s)*: </a>
                <div class="float-start d-inline-block response" style="width: 294px !important">
                  {{ data.Tel4 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Mail*: </a>
                <div class="float-start d-inline-block response" style="width: 235.5px !important">
                  {{ data.Email4 }}
                </div>
              </div>
            </div>

            <!--  -->
            <!--  -->
            <!--  -->
            <div class="float-start mt-2">
              <b class="fs-6">7 - CONTACTS TECHNIQUES DU DOMAINE</b>
            </div>

            <div class="dataBox float-start">
              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Nom*: </a>
                <div class="float-start d-inline-block response" style="width: 200px !important">
                  {{ data.LastName2 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Prénoms*: </a>
                <div class="float-start d-inline-block response" style="width: 348px !important">
                  {{ data.FirstName2 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Structure*: </a>
                <div class="float-start d-inline-block response" style="width: 200px !important">
                  {{ data.rStructure2 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Service et fonction*:
                </a>
                <div class="float-start d-inline-block response" style="width: 274px !important">
                  {{ data.rServiceFunction2 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Rue/Av./Secteur*: </a>
                <div class="float-start d-inline-block response" style="width: 553px !important">
                  {{ data.Address2 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Boîte et Code postal(e):
                </a>
                <div class="float-start d-inline-block response" style="width: 160px !important">
                  {{ data.PostalBox2 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Ville*: </a>
                <div class="float-start d-inline-block response" style="width: 140px !important">
                  {{ data.City2 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Pays*: </a>
                <div class="float-start d-inline-block response" style="width: 140px !important">
                  {{ data.CountryName2 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Téléphone(s)*: </a>
                <div class="float-start d-inline-block response" style="width: 294px !important">
                  {{ data.Tel2 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Mail*: </a>
                <div class="float-start d-inline-block response" style="width: 235.5px !important">
                  {{ data.Email2 }}
                </div>
              </div>
            </div>

            <div class="dataBox float-start mt-2">
              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Nom: </a>
                <div class="float-start d-inline-block response" style="width: 200px !important">
                  {{}}
                </div>
                <a class="float-start me-2 text-dark ms-1">Prénoms: </a>
                <div class="float-start d-inline-block response" style="width: 350px !important">
                  {{}}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Structure: </a>
                <div class="float-start d-inline-block response" style="width: 200px !important">
                  {{}}
                </div>
                <a class="float-start me-2 text-dark ms-1">Service et fonction:
                </a>
                <div class="float-start d-inline-block response" style="width: 280.5px !important">
                  {{}}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Rue/Av.: </a>
                <div class="float-start d-inline-block response" style="width: 598px !important">
                  {{}}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Boîte et Code postal(e):
                </a>
                <div class="float-start d-inline-block response" style="width: 165px !important">
                  {{}}
                </div>
                <a class="float-start me-2 text-dark ms-1">Ville: </a>
                <div class="float-start d-inline-block response" style="width: 140px !important">
                  {{}}
                </div>
                <a class="float-start me-2 text-dark ms-1">Pays: </a>
                <div class="float-start d-inline-block response" style="width: 140px !important">
                  {{}}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Téléphone(s): </a>
                <div class="float-start d-inline-block response" style="width: 294px !important">
                  {{}}
                </div>
                <a class="float-start me-2 text-dark ms-1">Mail: </a>
                <div class="float-start d-inline-block response" style="width: 240.5px !important">
                  {{}}
                </div>
              </div>
            </div>

            <div class="float-start mt-2">
              <b class="fs-6">8 - SERVEURS DE NOMS DE DOMAINES</b>
            </div>

            <div class="dataBox float-start">
              <div class="float-start mb-2">
                Indiquez en premier lieu le serveur primaire, puis le(s)
                serveur(s) secondaire(s). Un minimum de deux serveurs doit etre
                indiqué (dont un primaire et un sécondaire). Les noms doivent
                être complètement qualifiés (inclure le nom de domaine).
                <br />
                <br />
                <b>
                  L'organisme, ou son prestataire, s'engage à maintenir les
                  serveurs de noms déclarés ci-après fonctionnels et accessibles
                  en permanence à partir de tout équipement connecté à Internet.
                </b>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Serveur 1*: </a>
                <div class="float-start d-inline-block response" style="width: 160px !important">
                  {{ data.rServer_1 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Adresse IPv4*: </a>
                <div class="float-start d-inline-block response" style="width: 120px !important">
                  {{ data.rIPv4_1 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Adresse IPv6: </a>
                <div class="float-start d-inline-block response" style="width: 145px !important">
                  {{ data.rIPv6_1 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark">Serveur 2*: </a>
                <div class="float-start d-inline-block response" style="width: 160px !important">
                  {{ data.rServer_2 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Adresse IPv4*: </a>
                <div class="float-start d-inline-block response" style="width: 120px !important">
                  {{ data.rIPv4_2 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Adresse IPv6: </a>
                <div class="float-start d-inline-block response" style="width: 145px !important">
                  {{ data.rIPv6_2 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark" style="margin-right: 12px !important">Serveur 3:
                </a>
                <div class="float-start d-inline-block response" style="width: 160px !important">
                  {{ data.rServer_3 }}
                </div>
                <a class="float-start me-2 text-dark ms-1" style="margin-right: 12px !important">Adresse IPv4:
                </a>
                <div class="float-start d-inline-block response" style="width: 120px !important">
                  {{ data.rIPv4_3 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Adresse IPv6: </a>
                <div class="float-start d-inline-block response" style="width: 145px !important">
                  {{ data.rIPv6_3 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark" style="margin-right: 12px !important">Serveur 4:
                </a>
                <div class="float-start d-inline-block response" style="width: 160px !important">
                  {{ data.rServer_4 }}
                </div>
                <a class="float-start me-2 text-dark ms-1" style="margin-right: 12px !important">Adresse IPv4:
                </a>
                <div class="float-start d-inline-block response" style="width: 120px !important">
                  {{ data.rIPv4_4 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Adresse IPv6: </a>
                <div class="float-start d-inline-block response" style="width: 145px !important">
                  {{ data.rIPv6_4 }}
                </div>
              </div>
            </div>

            <div class="float-start text-center onePageFooter">
              <i style="font-size: 10px">Version 3.2 © ARCEP - Mars 2021</i>
              <a class="float-end text-dark" style="font-size: 12px">2</a>
            </div>
          </div>
        </section>

        <section class="onePage">
          <br />
          <br />
          <div class="text-start">
            <div class="float-start mt-2">
              <b class="fs-6">9 - SERVEURS DE NOMS DE DOMAINES POUR LES ZONES INVERSES
              </b>
            </div>

            <div class="dataBox float-start">
              <div class="float-start mb-2">
                <div>
                  Indiquez les réseaux éventuels à déclarer pour la
                  correspondance des adresses vers les noms des machines.
                </div>
                <div>
                  Cochez la case ci-dessous, si les serveurs de noms sont
                  identiques à ceux de la rubriques précédente 8.
                </div>
              </div>

              <div class="float-start checkresponse position-relative">
                <span class="d-inline-block"> </span>
                <a class="text-dark">Les serveurs sont identiques à ceux de la rubriques
                  précédente 8.</a>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark" style="margin-right: 12px !important">Serveur 1:
                </a>
                <div class="float-start d-inline-block response" style="width: 160px !important">
                  {{ data.zServer_1 }}
                </div>
                <a class="float-start me-2 text-dark ms-1" style="margin-right: 12px !important">Adresse IPv4:
                </a>
                <div class="float-start d-inline-block response" style="width: 120px !important">
                  {{ data.zIPv4_1 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Adresse IPv6: </a>
                <div class="float-start d-inline-block response" style="width: 145px !important">
                  {{ data.zIPv6_1 }}
                </div>
              </div>

              <div class="float-start mt-1">
                <a class="float-start me-2 text-dark" style="margin-right: 12px !important">Serveur 2:
                </a>
                <div class="float-start d-inline-block response" style="width: 160px !important">
                  {{ data.zServer_2 }}
                </div>
                <a class="float-start me-2 text-dark ms-1" style="margin-right: 12px !important">Adresse IPv4:
                </a>
                <div class="float-start d-inline-block response" style="width: 120px !important">
                  {{ data.zIPv4_2 }}
                </div>
                <a class="float-start me-2 text-dark ms-1">Adresse IPv6: </a>
                <div class="float-start d-inline-block response" style="width: 145px !important">
                  {{ data.zIPv6_2 }}
                </div>
              </div>
            </div>

            <div class="float-start mt-2">
              <b class="fs-6">10 - ENGAGEMENTS</b>
            </div>

            <div class="dataBox float-start rDomainTitle">
              <div class="float-start mb-2">
                <div>
                  <b style="font-size: 12px !important">
                    i) L'organisme sengage sur l'exactitude des informations
                    fournies dans ce document.
                  </b>
                </div>

                <br />
                <div>
                  <b style="font-size: 12px !important"> ii) </b>
                  <span style="font-size: 12px !important">
                    Le demandeur a pris connaissance de la notice jointe à ce
                    formulaire et s'engage à suivre les indications de l'ABDI
                    quant à la gestion de son domaine, du ou des sous-domaines
                    et des serveurs de noms. Il s'engage à enregistrer dans sa
                    zone, les zones des autres services dépendant du même
                    organisme qui lui feraient la demande.
                  </span>
                </div>

                <br />
                <div>
                  <b style="font-size: 12px !important"> iii) </b>
                  <span style="font-size: 12px !important">
                    L'organisme s'engage à notifier par écrit à l'ABDI, tout
                    changement dans la liste des serveurs, des personnes gérant
                    le domaine de l'organisme et plus généralement, tout
                    changement concernant les informations fournies dans ce
                    document.
                  </span>
                </div>

                <br />
                <div>
                  <b style="font-size: 12px !important"> iv) </b>
                  <span style="font-size: 12px !important">
                    Tout courrier électronique envoyé à l'adresse
                    <i>"postmaster@votre-domaine.bf"</i> doit être transmissible
                    à une personne physique.
                  </span>
                </div>

                <div class="float-end mt-2">
                  <a class="float-start me-2 text-dark ms-1">Fait à: </a>
                  <div class="float-start d-inline-block response bg-white" style="width: 200px !important">
                    {{ here }}
                  </div>
                  <a class="float-start me-2 text-dark ms-1">Le: </a>
                  <div class="float-start d-inline-block response bg-white" style="width: 200px !important">
                    {{ todayDate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="sign">
              <div class="text-center">
                <b style="font-size: 13px">Signature du Responsable administratif et cachet de
                  l'organisme</b>
              </div>
              <div style="color: rgb(199, 46, 46); font-size: 12px">
                (Faire précéder de votre nom, de votre fonction dans
                l'organisme, et de la mention "Lu et approuvé")
              </div>
            </div>

            <div class="float-start text-center onePageFooter">
              <i style="font-size: 10px">Version 3.2 © ARCEP - Mars 2021</i>
              <a class="float-end text-dark" style="font-size: 12px">3</a>
            </div>
          </div>
        </section>

        <section class="onePage">
          <div class="text-start">
            <div class="docs mt-4">
              <div class="text-center mb-2">
                <b class="fs-4">COMPLEMENTS DE PIECES A FOURNIR</b>
              </div>

              <b>A. SI LE DEMANDEUR EST UNE PERSONNE PHYSIQUE</b>
              <br />
              <div class="float-start checkdoc w-100">
                <a class="text-dark float-start">
                  (1) Une photocopie de la pièce d'identité (CNIB ou passeport)
                  du demandeur
                </a>
                <span class="float-end position-relative">
                  <i class="bi bi-x" v-if="data.natureRegister == 'PERSON'"></i>
                </span>
              </div>

              <br />
              <br />
              <b>B. SI LE DEMANDEUR EST UNE STRUCTURE (Etatique, Association,
                ONG, Société, Organisme...)</b>
              <br />
              <a class="text-dark float-start">
                (1) Un document administratif montrant l'objet ou l'activité de
                la structure, par exemple (au choix):
              </a>
              <br />
              <div class="float-start checkdoc w-100">
                <a class="text-dark float-start ms-4">
                  (1.1) Un extrait du registre de commerce et de crédit mobilier
                  (RCCM) pour une entreprise
                </a>
                <span class="float-end position-relative">
                  <i class="bi bi-x" v-if="data.natureRegister == 'STRUCTURE' &&
                    data.cDocs == 'option1'
                  "></i>
                </span>
              </div>
              <br />
              <div class="float-start checkdoc w-100">
                <a class="text-dark float-start ms-4">
                  (1.2) Un numéro Imprimé Fiscal Unique (IFU) ou attestation
                  d'activité ou d'existence
                </a>
                <span class="float-end position-relative">
                  <i class="bi bi-x" v-if="data.natureRegister == 'STRUCTURE' &&
                    data.cDocs == 'option2'
                  ">
                  </i>
                </span>
              </div>
              <br />
              <div class="float-start checkdoc w-100">
                <a class="text-dark float-start ms-4">
                  (1.3) Un agrément ou un récépissé de reconnaissance, ou tout
                  autre document prouvant l'existence
                </a>
                <span class="float-end position-relative">
                  <i class="bi bi-x" v-if="data.natureRegister == 'STRUCTURE' &&
                    data.cDocs == 'option3'
                  ">
                  </i>
                </span>
              </div>
              <br />
              <div class="float-start checkdoc w-100">
                <a class="text-dark float-start" style="width: 660px !important">
                  (2) Un procès verbal ou document désignant le mandataire dont
                  les informations figurent dans la section "5 - Responsable
                  Administratif du domaine"
                </a>
                <span class="float-end position-relative"> </span>
              </div>

              <br />
              <br />
              <br />
              <b>C. SI LE DEMANDEUR EST UN PRESTATAIRE AGISSANT POUR LE COMPTE
                D'UN CLIENT</b>
              <br />
              <div class="float-start checkdoc w-100">
                <a class="text-dark float-start">
                  (1) Un document administratif montrant l'objet ou l'activité
                  du prestataire (voir la section B.1 ci-dessus)
                </a>
                <span class="float-end position-relative">
                  <i class="bi bi-x" v-if="data.natureRegister == 'PRESTATAIRE'"></i>
                </span>
              </div>
              <br />
              <div class="float-start checkdoc w-100">
                <a class="text-dark float-start">
                  (2) Un document d'accréditation ICANN (Internet Corporation
                  for Assigned Names and Numbers) ou autre
                </a>
                <span class="float-end position-relative"> </span>
              </div>
              <br />
              <div class="float-start checkdoc w-100">
                <a class="text-dark float-start">
                  (3) Un document (lettre...) écrit et signé par le Client,
                  donnant mandat au prestataire d'entreprendre la démarche en
                  son nom
                </a>
                <span class="float-end position-relative">
                  <i class="bi bi-x" v-if="data.natureRegister == 'PRESTATAIRE'"></i>
                </span>
              </div>
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <div class="text-center">
              <h4>AVIS DE L'ABDI</h4>
              <div class="avis"></div>
            </div>

            <div class="float-start text-center onePageFooter">
              <i style="font-size: 10px">Version 3.2 © ARCEP - Mars 2021</i>
              <a class="float-end text-dark" style="font-size: 12px">4</a>
            </div>
          </div>
        </section>
      </div>
    </center>
  </main>
</template>

<script>
const $ = require("jquery");
import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
import Functions from "/src/common/js/functions";
import axios from "axios";
import popup from "/src/common/js/popup";
//
// import html2pdf from "html2pdf.js";
import * as localforage from "localforage";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";
//
import countryList from "/src/components/countryList.json";

//login component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      data: {},
      //
      pageName: "Details du formulaire de nom de domaine (.bf)",
      //
      todayDate: "",
      here: "",
    };
  },

  mounted() {
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    waitLoader.show();
    //
    localforage.getItem("ecocloud/User").then(async (result) => {
      let jsData = result;
      if (jsData == "" || jsData == "{}" || jsData == null) {
        //
        this.$router.replace("/login");
        return;
      }
      jsData = JSON.parse(jsData);
      this.usersStore.User = jsData;
      //
      var today = new Date();
      var day =
        today.getDate() >= 10 ? today.getDate() : "0" + today.getDate();
      var month =
        today.getMonth() + 1 >= 10
          ? today.getMonth() + 1
          : "0" + (today.getMonth() + 1);
      var year = today.getFullYear();
      this.todayDate = day + "/" + month + "/" + year;
      //
      this.here = this.usersStore.User.data.city;
      //
      this.data = this.usersStore.User.rDomainBF;
      this.data.DomainOnly = this.data.Domain.substring(
        0,
        this.data.Domain.lastIndexOf(".")
      );
      //
      //get countryName
      countryList.forEach((item) => {
        if (item.code == this.data.Country1) {
          this.data.CountryName1 = item.name;
        }
        if (item.code == this.data.Country2) {
          this.data.CountryName2 = item.name;
        }
        if (item.code == this.data.Country3) {
          this.data.CountryName3 = item.name;
        }
        if (item.code == this.data.Country4) {
          this.data.CountryName4 = item.name;
        }
      });
      //
      //First and Last names
      this.data.LastName3 = this.data.Fname3.substring(
        0,
        this.data.Fname3.indexOf(" ")
      );
      this.data.FirstName3 = this.data.Fname3.substring(
        this.data.Fname3.indexOf(" ") + 1
      );
      //
      this.data.LastName2 = this.data.Fname2.substring(
        0,
        this.data.Fname2.indexOf(" ")
      );
      this.data.FirstName2 = this.data.Fname2.substring(
        this.data.Fname2.indexOf(" ") + 1
      );
      //
    })
      .catch((err) => {
        console.log(err);
        // This code runs if there were any errors.
        this.$router.replace("/client/domain-contact-details-bf");
      });
    //
    this.__update();
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
        waitLoader.hide();
      }, 1);
    },

    update() { },

    validate() {
      waitLoader.show();
      //
      let data = this.data;
      //
      //generate db form data
      let fd = new FormData();
      fd.append("query", "generate-external-domain-bf-form/" + Functions.correctQueryString(JSON.stringify(data)))
      //
      axios.post(Globals.website_url + "php/api.php", fd).then((response) => {
        let h = response.data;
        //on error
        if (h == "0" || typeof (h) != "object") {
          waitLoader.hide();
          //say error
          popup.action = "Notification";
          popup.setTitle("Notification");
          popup.setMessage(
            "Erreur d'enregistrement des données!<br>Veuillez reéssayer."
          );
          popup.setButton("Ok");
          popup.show();
          return;
        }
        //
        //
        //create pdf
        const options = {
          "method": "POST",
          "url": "https://api.pdfendpoint.com/v1/convert",
          "headers": {
            "Content-Type": "application/json",
            "Authorization": h.auth
          },
          "data": JSON.stringify({
            "url": "https://client.ecodev.dev/?ids=" + h.ids + "#/domain-bf-pdf",
            "margin_top": "0px",
            "margin_bottom": "0px",
            "margin_left": "0px",
            "margin_right": "0px",
            "page_width": "724px",
            "page_height": "1024px",
            "title": "ABDI form",
            "author": "ECODEV",
            "creator": "ECODEV",
            "producer": "pdfEndpoint"
          })
        };

        axios.request(options).then(function (response) {
          //
          axios.get(response.data.data.url, { responseType: 'blob' }).then(response => {
            waitLoader.hide();
            //
            let a = document.createElement("a");
            a.href = URL.createObjectURL(response.data);
            a.target = "_blank";
            a.download = "Nomdedomaine_" + data.Domain + ".pdf";
            a.click();
            URL.revokeObjectURL(response.data);
            //
          }).catch(error => {
            console.log(error);
            waitLoader.hide();
            //
            popup.action = "Notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Erreur lors du téléchargement du document !<br>Veuillez reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
          });
          //
        }).catch(function (error) {
          console.error(error);
        });

        //
      }).catch((error) => {
        console.log(error);
        //
        waitLoader.hide();
      });
    },
    //
  },
};
</script>

<style scoped>
@import "intl-tel-input/build/css/intlTelInput.css";

#rDom {
  display: block !important;
  padding-bottom: 0.25em;
  max-width: 100vw !important;
}

#rDomMenu {
  transform: scale(0.85);
  transform-origin: center top;
  margin-left: 32px;
}

#downloadPDF {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 6px 12px;
  border-radius: 50px;
  background-color: white;
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
  margin-right: 32px;
}

#downloadPDF:hover {
  background-color: rgb(199, 46, 46);
}

#downloadPDF:hover i {
  color: white !important;
}

#downloadPDF:hover a {
  color: white !important;
}

#downloadPDF i {
  font-size: 24px;
}

.btn {
  margin-right: 32px;
  margin-top: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 50%;
  padding: 6px 12px;
  background-color: white;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
  cursor: pointer;
}

.btn:hover {
  background-color: rgb(199, 46, 46);
}

.btn i {
  font-size: 24px;
  color: black;
}

.btn:hover i {
  color: white;
}

#process {
  margin: 16px 0 8px 0;
  background-color: rgb(255, 230, 230);
  color: rgb(199, 46, 46);
  padding: 8px 16px;
  border-radius: 6px;
  width: 768px;
  display: block;
  width: 100%;
  max-width: 768px;
  /*  */
  font-size: 0.75em;
}

#rDomain {
  display: inline-block;
  position: relative;
  top: 8px;
  width: 768px;
  max-width: 768px;
  background-color: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
  height: max-content;
  margin-bottom: 24px;
}

.onePage {
  position: relative;
  float: left;
  width: 100% !important;
  height: 1110px !important;
  padding: 2.5em 2.5em;
  border-bottom: dashed 1px black;
}

.onePageFooter {
  position: absolute !important;
  bottom: 48px !important;
  left: 32px !important;
  right: 32px !important;
}

#headOfDoc {}

#headOfDoc #separator {
  float: left;
  width: 1px;
  height: 34px;
  margin: 4px 10px;
  background-color: black;
  height: 1.5em;
}

#headOfDoc p {
  float: left;
  font-size: 10px;
}

.rDomainTitle {
  width: 100%;
  height: max-content;
  border-radius: 6px;
  border: solid 1px black;
  background-color: #eee;
  padding: 4px 10px;
}

.rDomainLabel {
  margin-left: 24px;
  color: black;
  float: left;
  margin-bottom: 12px;
}

#rDomain label {
  font-size: 12px;
}

.rDomainValue {
  margin-left: 12px;
  color: black;
  background-color: rgb(228, 226, 252);
  padding: 5px;
  border: dashed 1px black;
  font-size: 12px;
}

.contact {
  font-size: 10px !important;
}

.contact a {
  color: #0075d4;
  text-decoration: underline !important;
}

/*  */
/*  */
/*  */
/*  */

.dataBox {
  border: solid 1px black;
  border-radius: 8px;
  padding: 8px 18px;
}

.dataBox * {
  font-size: 11px !important;
}

.response {
  border: dotted 1px black;
  padding: 1px 2px;
  height: 22px;
}

.checkresponse {
  margin-right: 48px !important;
}

.checkresponse span {
  border: solid 1px black;
  width: 10px !important;
  height: 10px !important;
  margin-right: 2px;
}

.checkresponse span i {
  position: absolute;
  top: -6px;
  left: -4px;
  font-size: 18px !important;
}

.sign {
  float: right;
  width: 555px;
}

.docs * {
  font-size: 11px;
}

.checkdoc {}

.checkdoc span {
  border: solid 1px black;
  width: 10px !important;
  height: 10px !important;
}

.checkdoc span i {
  position: absolute;
  top: -9px;
  left: -5px;
  font-size: 18px !important;
}

.avis {
  width: 100%;
  height: 300px;
  border: solid 1px black;
}
</style>
