<template>
    <main id="accessRenew">
        <form @submit.prevent="validate();" class="text-center">
            <img src="@/assets/images/logo-ecodev.png">
            <!--  -->
            <h3>Veuillez saisir votre mot de passe pour vous connecter à votre compte et éffectuer votre reabonnement.
            </h3>

            <div class="input-group mb-2">
                <span class="input-group-text" id="basic-addon1"><i class="bi bi-lock-fill"></i></span>

                <input type="password" class="form-control text-center" id="password" required
                    placeholder="Mot de passe">

                <span class="input-group-text" @click="password_show_hide()">
                    <i class="bi bi-eye-fill" id="show_eye"></i>
                    <i class="bi bi-eye-slash-fill d-none" id="hide_eye"></i>
                </span>
            </div>

            <br>
            <div class="w-100 d-flex justify-content-center">
                <button type="submit">Se connecter</button>
            </div>
        </form>
    </main>
</template>

<script>
import axios from "axios";
import localforage from "localforage";
//
import functions from "/src/common/js/functions";
import waitLoader from "/src/common/js/waitLoader";
import popup from "/src/common/js/popup";
import Globals from "/src/common/js/Globals";

import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";



export default {
    computed: {
        ...mapStores(useUsersStore),
    },

    data() {
        return {
            user_id: "",
            sub_ids: "",
            offer_id: -1,
            std: 0,
        };
    },

    mounted() {
        //
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.user_id = urlParams.get('aid');
        this.sub_ids = urlParams.get('sids');
        this.offer_id = urlParams.get('oid');
        this.std = urlParams.get('std');
    },

    methods: {
        validate() {
            let password = document.getElementById("password").value;
            //on good data
            waitLoader.show();
            //
            password = functions.correctQueryString(password);

            let $self = this;
            //
            axios.post(Globals.website_url + "php/api.php?query=login-user-with-id/" + this.user_id + "/" + password).then(async (response) => {
                let h = response.data;
                //on error
                if (h == null || h == [] || h.length == 0) {
                    //say error
                    popup.action = "notification";
                    popup.setTitle("Notification");
                    popup.setMessage("Le mot de passe saisi est incorrect !<br>Veuillez reéssayer.");
                    popup.setButton("Ok");
                    popup.show();
                    //
                    waitLoader.hide();
                    return false;
                }
                try {
                    //
                    const resp = await axios.get(Globals.website_url + "php/api.php?query=get-offer-by-id/" + $self.sub_ids);
                    let h0 = resp.data;
                    //
                    $self.usersStore.User.account.curOfferDomain = h0.domain_name;
                    $self.usersStore.User.account.curSubscriptionIds = $self.sub_ids;
                    $self.usersStore.User.account.curServiceId = $self.offer_id;
                    //
                    //
                    //on success
                    $self.usersStore.User.account.id = h.id;
                    $self.usersStore.User.account.email = h.email;
                    $self.usersStore.User.account.password = h.passwd;
                    $self.usersStore.User.account.atLogin = true;
                    $self.usersStore.User.data = h;
                    //save cache data
                    localforage.setItem('ecocloud/User', JSON.stringify($self.usersStore.User))
                        .then(() => {
                            waitLoader.hide();
                            //
                            if (this.std == 0) {
                                this.$router.push("/client/renew/chooseOffer");
                            }
                            else if (this.std == 1) {
                                this.$router.push("/client/reserveDomain/renewDomain");
                            }
                        }).catch(function (err) {
                            // we got an error
                            waitLoader.hide();
                            console.log(err);
                        });
                }
                catch (error) {
                    console.log(error);
                    waitLoader.hide();
                    //say error
                    popup.action = "notification";
                    popup.setTitle("Notification");
                    popup.setMessage("Problème de connexion!<br>Veuillez reéssayer.");
                    popup.setButton("Ok");
                    popup.show();
                }
            }).catch((error) => {
                console.log(error);
                waitLoader.hide();
                //say error
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage("Problème de connexion!<br>Veuillez reéssayer.");
                popup.setButton("Ok");
                popup.show();
            });
        },
        //

        password_show_hide() {
            var x = document.getElementById("password");
            var show_eye = document.getElementById("show_eye");
            var hide_eye = document.getElementById("hide_eye");
            hide_eye.classList.remove("d-none");
            if (x.type === "password") {
                x.type = "text";
                show_eye.style.display = "none";
                hide_eye.style.display = "block";
            } else {
                x.type = "password";
                show_eye.style.display = "block";
                hide_eye.style.display = "none";
            }
        },
    }
}
</script>

<style scoped>
main {
    width: 100%;
    height: 100vh;
    background: url("/src/assets/images/app-bg.jpg") no-repeat center center fixed;
    background-size: cover;
}

form {
    color: black;
    max-width: 680px;
    width: 100%;
    background-color: #F2FBFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    float: left;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
    padding: 112px;
}

form img {
    width: 260px;
    margin-bottom: 32px;
}


form h3 {
    margin-bottom: 24px;
}

form input {
    border: solid 1px #ddd;
    padding: 12px 12px;
}

form button[type=submit] {
    margin-left: 12px;
    margin-top: 32px;
    border: none;
    border-radius: 3px;
    padding: 8px 0;
    width: 240px;
    box-sizing: content-box;
    color: white;
    background-color: #111a2b;
    font-size: 17px;
}

form button[type=submit]:hover {
    color: white;
    background-color: #1b2842;
}

form button[type=submit] i {
    color: rgba(123, 255, 0);
    font-size: 16px;
}

form button[type=submit]:hover {
    background-color: #346;
}

/**/
@media only screen and (max-width: 480px) {
    form {
        box-shadow: none;
        border: solid 1px #ccc;
        border-left: none;
        border-right: none;
        border-radius: 0;
    }
}

@media only screen and (max-height: 512px) {
    form {
        top: 0;
        transform: translateY(0) translateX(-50%);
    }
}
</style>