<template>
  <main id="supportsMenu">
    <br>
    <h1>Support & Assistance</h1>

    <div id="supportsMenuBox" class="justify-content-center d-flex mt-5">
      <div class="supportOthers">
        <a :href="'mailto:' + support.email">
          <button class="supportsBtn">
            <i class="d-block bi bi-envelope-fill"></i>
            <h4>Envoyer un mail</h4>
            <p>{{ support.email }}</p>
          </button>
        </a>

        <a :href="'tel:' + support.tel">
          <button class="supportsBtn">
            <i class="d-block bi bi-telephone-fill"></i>
            <h4>Nous Appeler</h4>
            <p>{{ support.tel }}</p>
          </button>
        </a>
      </div>

      <router-link to="/client/Supports/chat">
        <button class="supportsBtn odd">
          <i class="d-block bi bi-chat-left-dots-fill"></i>
          <h4>Chat instantané</h4>
          <p>Discutez en instantané avec un assistant à propos de vos préocupations sur ecoweb.</p>
        </button>
      </router-link>
    </div>
  </main>
</template>

<script>
const $ = require("jquery");
import axios from "axios";
//
import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
// import functions from "/src/common/js/functions";
// import popup from "/src/common/js/popup";
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";


//login component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      pageName: "Supports Menu",
      support: {},
    };
  },

  mounted() {
    waitLoader.show();
    //
    $("#routeName")[0].innerHTML = this.pageName;
    //
    this.__update();
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
        waitLoader.hide();
      }, 1);
    },

    async update() {
      try {
        const resp = await axios.get(Globals.website_url + "php/api.php?query=get-support-contacts");
        this.support = resp.data;
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
      //
      waitLoader.hide();
    },
  }
}
</script>

<style scoped>
#supportsMenu {
  color: black;
  width: 100%;
}

.supportsBtn {
  width: 260px;
  height: 260px;
  border: none;
  border-radius: 32px;
  background-color: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.125);
  margin: 0 12px;
  transition: all 0.15s ease;
}

.supportsBtn.odd {
  background: linear-gradient(135deg, #ff643f 0%, #fe575b 100%);
  color: white;
}

.supportsBtn:hover {
  transform: scale(1.03);
}

.supportsBtn i {
  font-size: 36px;
  color: #f60;
}

.supportsBtn.odd i {
  color: white;
}

.supportsBtn h4 {
  font-weight: 600;
}





@media only screen and (max-width:1111px) {
  #supportsMenuBox {
    display: block !important;
  }

  .supportsBtn {
    margin: 12px 12px;
  }
}

@media only screen and (max-width:296px) {
  #supportsMenuBox {
    margin-top: 4px !important;
  }

  .supportsBtn {
    margin: 6px 0px;
  }
}
</style>