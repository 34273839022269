import UserChangePhoto from '../views/client/UserChangePhoto.vue';
import ClientMain from "../views/client/ClientMain.vue";
import ClientHome from "../views/client/ClientHome.vue";
//
import UserDataMain from '../views/client/UserDataMain.vue';
import UserDataInfos from '../views/client/UserDataInfos.vue';
import UserDataEdit from '../views/client/UserDataEdit.vue';
import UserDataPasswordEdit from '../views/client/UserDataPasswordEdit.vue';
import UserDataEnterpriseEdit from '../views/client/UserDataEnterpriseEdit.vue';
//
import SupportsMain from '../views/client/SupportsMain.vue';
import SupportsChat from '../views/client/SupportsChat.vue';
import SupportsMenu from '../views/client/SupportsMenu.vue';
//
import SubsMain from '../views/client/SubsMain.vue';
import SubsDetailsVue from '../views/client/SubsDetailsVue.vue';
import SubsFacturesVue from '../views/client/SubsFacturesVue.vue';
//
import ServicesVue from '../views/client/ServicesVue.vue';
//
import SubscribeOfferChoose from '../views/client/SubscribeOfferChoose.vue';
import RenewOfferChoose from '../views/client/RenewOfferChoose.vue';
import PaymentVue from "../views/client/Payment.vue";
//
import Messagerie from '../views/client/MessagerieVue.vue';
//
import RegisterDomain from '../views/client/RegisterDomain.vue';
//
import RegisterDomainBF from '../views/client/RegisterDomainBF.vue';
import DomainBfDetails from '../views/client/DomainBfDetails.vue';
import DomainBfDocs from '../views/client/DomainBfDocs.vue';
import DomainBfFormAlt from '../views/client/DomainBfFormAlt.vue';
import DomainBfDocsAlt from '../views/client/DomainBfDocsAlt.vue';
//
import DomainsVue from '../views/client/DomainsVue.vue';
import UpdateDomainContactsBF from '../views/client/UpdateDomainContactsBF.vue';
import ChequePayvue from '../views/client/chequePay.vue';
import TransfertPayvue from '../views/client/transfertPay.vue';


const routes = [
    {
        path: "/client-change-photo",
        component: UserChangePhoto
    },
    //
    //
    //
    {
        path: "/",
        redirect: "/client/Home",
    },
    //
    {
        path: "/client/",
        redirect: "/client/Home",
    },
    {
        name: "client",
        path: "/client",
        component: ClientMain,
        children: [
            {
                path: 'transfertPay',
                name: 'TransfertPay',
                component: TransfertPayvue
            },
            {
                path: 'chequePay',
                name: 'ChequePay',
                component: ChequePayvue
            },
            //
            //
            {
                name: "client.Home",
                path: "Home",
                component: ClientHome,
            },
            {
                path: "UserData",
                component: UserDataMain,
                children: [
                    {
                        path: "Infos",
                        component: UserDataInfos,
                    },
                    {
                        path: "EditData",
                        component: UserDataEdit,
                    },
                    {
                        path: "EditPassword",
                        component: UserDataPasswordEdit,
                    },
                    {
                        path: "EntrepriseData",
                        component: UserDataEnterpriseEdit,
                    },
                ]
            },
            {
                path: "Supports",
                redirect: "menu",
            },
            {
                path: "Supports",
                component: SupportsMain,
                children: [
                    {
                        path: "menu",
                        component: SupportsMenu,
                    },
                    {
                        name: "client-support-chat",
                        path: "chat",
                        component: SupportsChat,
                    },
                ]
            },
            {
                path: "Subscriptions",
                component: SubsMain,
                children: [
                    {
                        path: "services",
                        component: SubsDetailsVue,
                    },
                    {
                        path: "Invoices",
                        component: SubsFacturesVue,
                    },
                ]
            },
            {
                path: "Offers",
                component: ServicesVue,
            }
            ,
            //------------------------------
            //PAYEMENT PART
            {
                path: "choose-offer-domain",
                component: SubscribeOfferChoose,
            },
            {
                path: "choose-renew-offer",
                component: RenewOfferChoose,
            },
            {
                path: "payment",
                component: PaymentVue,
            },
            //-------------------------------
            {
                path: "email-panel",
                component: Messagerie,
            },
            {
                path: "domains",
                component: DomainsVue,
            },
            {
                path: "update-domain-contacts",
                component: UpdateDomainContactsBF,
            },
            //
            //
            //
            {
                path: "domain-contact-details",
                component: RegisterDomain,
            },
            //
            //
            //
            {
                path: "domain-contact-details-bf",
                component: RegisterDomainBF,
            },
            {
                path: "domainBfDetails",
                component: DomainBfDetails
            },
            {
                path: "domainBfDocs",
                component: DomainBfDocs
            },
            {
                path: "domainBfFormAlt",
                component: DomainBfFormAlt
            },
            {
                path: "domainBfDocsAlt",
                component: DomainBfDocsAlt
            },

        ]
    }
];

export default routes;