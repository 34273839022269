<template>
  <router-view></router-view>
</template>

<script>
const $ = require("jquery");
import popup from '/src/common/js/popup';


export default {
  mounted() {
    //Popup mounted
    $("#popupYes").click(function () {
      popup.fireButton(this);
    });

    $("#popupNo").click(function () {
      popup.fireButton(this);
    });

    $("#popupCancel").click(function () {
      popup.fireButton(this);
    });

    $("#popupCloseBtn").click(function () {
      popup.hide();
    });
    //
    //
    //
  }
}
</script>

<style></style>
