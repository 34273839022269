<template>
  <div id="userDataInfos">
    <div>
      <h4 class="d-inline w-auto">Informations personnelles</h4>
      <img class="d-inline userEditPhoto headerUserImg"
        :src="(userData.photo_path == '' || userData.photo_path == null) ? './user-default-image.webp' : server_url + 'media/images/' + userData.photo_path">
    </div>
    <br>

    <a class="userDataInfosTitle">Titulaire du compte</a>
    <div class="userDataInfosItems">
      <div class="row userDataInfosItem">
        <b class="col-sm-4 col-12">Code client</b>
        <a class="col-sm-8 col-12 animated-text">{{ userData.id }}</a>
      </div>
      <div class="row userDataInfosItem">
        <b class="col-sm-4 col-12">Nom</b>
        <a class="col-sm-8 col-12 animated-text">{{ getFirstName(userData.fname) }}</a>
      </div>
      <div class="row userDataInfosItem">
        <b class="col-sm-4 col-12">Prénom</b>
        <a class="col-sm-8 col-12 animated-text">{{ getLastName(userData.fname) }}</a>
      </div>
    </div>

    <a class="userDataInfosTitle">Email & Téléphone</a>
    <div class="userDataInfosItems">
      <div class="row userDataInfosItem">
        <b class="col-sm-4 col-12">Email</b>
        <a class="col-sm-8 col-12 animated-text">{{ userData.email }}</a>
      </div>
      <div class="row userDataInfosItem">
        <b class="col-sm-4 col-12">Téléphone</b>
        <a class="col-sm-8 col-12 animated-text">{{ userData.tel }}</a>
      </div>
    </div>

    <a class="userDataInfosTitle">Localisation</a>
    <div class="userDataInfosItems">
      <div class="row userDataInfosItem">
        <b class="col-sm-4 col-12">Adresse</b>
        <a class="col-sm-8 col-12 animated-text">{{ userData.home_address }}</a>
      </div>
      <div class="row userDataInfosItem">
        <b class="col-sm-4 col-12">Pays</b>
        <a class="col-sm-8 col-12 animated-text">{{ userData.countryName }}</a>
      </div>
      <div class="row userDataInfosItem">
        <b class="col-sm-4 col-12">Ville</b>
        <a class="col-sm-8 col-12 animated-text">{{ userData.city }}</a>
      </div>
    </div>

    <center>
      <router-link to="/client/UserData/EditData" class="form-control userDataInfosBtn my-1 mx-1">
        <i class="bi bi-pencil-fill"></i>
        Modifier mon profil
      </router-link>

      <router-link to="/client/UserData/EditPassword" class="form-control userDataInfosBtn my-1 mx-1">
        <i class="bi bi-key-fill"></i>
        Changer de mot de passe
      </router-link>

      <router-link to="/client-change-photo" class="form-control userDataInfosBtn my-1 mx-1">
        <i class="bi bi-person-circle"></i>
        Changer ma photo
      </router-link>
    </center>
  </div>
</template>

<script>
import functions from "/src/common/js/functions";
import axios from "axios";
import Globals from "/src/common/js/Globals";
import popup from "/src/common/js/popup";
import waitLoader from "/src/common/js/waitLoader";
const $ = require("jquery");
//
import countryList from "/src/components/countryList.json";
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";

//UserDataInfos component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      pageName: "Mes Informations personnelles",

      userData: {
        id: "",
        fname: "",
        email: "",
        tel: "",
        home_address: "",
        country: "",
        countryName: "",
        city: '',
        photo_path: ''
      },

      isDataCompleted: false,

      server_url: "",
    }
  },

  mounted() {
    //
    waitLoader.show();
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    this.server_url = Globals.website_url;
    //
    setTimeout(() => {
      Globals.UserData.updateTabs();
    }, 250);
    //
    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
        waitLoader.hide();
      }, 1);
    },

    async update() {
      //get All user data in json format
      let id = this.usersStore.User.account.id;
      id = functions.correctQueryString(id);
      try {
        const resp = await axios.get(Globals.website_url + "../api/get-user-data-by-id/" + id);
        if (!resp.data) {
          this.userData = {};
          return;
        }
        this.userData = resp.data;
        //get countryName
        countryList.forEach((item) => {
          if (item.code == this.userData.country) {
            this.userData.countryName = item.name;
          }
        });
        //
        //check if user has sucessfully completed his data
        this.isDataCompleted = this.userData.creating_step == 1 ? true : false;
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
      waitLoader.hide();
    },

    getFirstName(str) {
      if (str == null) return "";
      return str.substr(0, str.indexOf(" "));
    },

    getLastName(str) {
      if (str == null) return "";
      return str.substr(str.indexOf(" ") + 1, 256);
    },
  }
};
</script>

<style scoped>
/*----------------------------------
USER DATA INFO
----------------------------------*/
#userDataInfos {
  max-width: 768px;
  width: 100%;
  margin: auto;
  background-color: #F2FBFF;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  height: max-content;
  padding: 28px;
  color: #111a2b;
  text-align: left;
}



.headerUserImg {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}



.userDataInfosTitle {
  color: #ff6600 !important;
}

.userDataInfosItems {
  margin-top: 8px;
  width: 100%;
  height: max-content;
}

.userDataInfosItems {
  margin-bottom: 32px;
}

.userDataInfosItem:nth-child(odd) {
  background-color: white;
}

.userDataInfosItem b {}

.userDataInfosItem a {
  color: black;
}

.userDataInfosBtn {
  display: inline-block;
  border: none;
  padding: 8px 24px;
  width: auto;
  box-sizing: content-box;
  color: white;
  background-color: #111a2b;
  font-size: 16px;
}

.userDataInfosBtn:hover {
  color: white;
  background-color: #1b2842;
}

.userDataInfosBtn i {
  color: rgba(123, 255, 0);
  font-size: 16px;
}





.userEditPhoto {
  position: relative;
  top: -10px;
  float: right;
  width: 48px;
  height: 48px;
}






.animated-text {
  animation: animated-text 0.4s ease;
}

@keyframes animated-text {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}


@media only screen and (max-width:512px) {
  #userDataInfos {
    padding: 14px;
  }

  .userEditPhoto {
    margin-top: 8px;
  }

  .userDataInfosBtn {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>