<template>
  <div id="userEnterpriseDataEdit">
    <h4>Details de Facturation</h4>
    <br />

    <form class="row" v-on:submit.prevent="validate">
      <div class="col-sm-6 col-12">
        <div class="form-floating">
          <input type="text" id="userEnterpriseDataEditName" class="form-control" spellcheck="false"
            placeholder="Akaeyes Team" :value="userEnterprise.name" @input="resetInput">
          <label for="userEnterpriseDataEditName">Nom</label>
        </div>
        <div class="d-block mb-3 text-secondary">
          <i class="bi bi-info-circle-fill me-1"></i>
          <a>C'est le nom qui apparaitra sur vos factures.</a>
        </div>

        <div class="mb-3">
          <label for="userEnterpriseDataEditTel1" class="w-100">téléphone 1</label>
          <input type="tel" id="userEnterpriseDataEditTel1" class="form-control" @input="resetInput">
        </div>

        <div class="mb-3">
          <label for="userEnterpriseDataEditTel2" class="w-100">téléphone 2 (facultatif)</label>
          <input type="tel" id="userEnterpriseDataEditTel2" class="form-control" @input="resetInput" non-required>
        </div>
      </div>

      <div class="col-12 col-sm-6">

        <div class="form-floating mb-3">
          <CountryList id="userEnterpriseDataEditCountry"></CountryList>
          <label for="userEnterpriseDataEditCountry">Pays</label>
        </div>

        <div class="form-floating mb-3">
          <input type="text" id="userEnterpriseDataEditCity" class="form-control" placeholder="Ouagadougou"
            :value="userEnterprise.city" @input="resetInput">
          <label for="userEnterpriseDataEditCity">Ville</label>
        </div>

        <div class="form-floating">
          <input type="text" id="userEnterpriseDataEditAddress" class="form-control" placeholder="Ouaga 2000 Azimmo"
            :value="userEnterprise.home_address" @input="resetInput">
          <label for="userEnterpriseDataEditAddress">Adresse</label>
        </div>
        <div class="d-block mb-3 text-secondary">
          <i class="bi bi-info-circle-fill me-1"></i>
          <a>Exemple: ouaga 2000 azimmo</a>
        </div>
      </div>

      <div class="col-12 d-flex justify-content-center">
        <button type="submit">
          ENREGISTRER LES MODIFICATIONS
          <i class="bi bi-check-lg"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
const $ = require("jquery");
import countryList from "/src/components/countryList.vue";
import functions from "/src/common/js/functions";
import popup from "/src/common/js/popup";
import axios from "axios";
import waitLoader from "/src/common/js/waitLoader";
import Globals from "/src/common/js/Globals";
import intlTelInput from 'intl-tel-input';
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";

//UserDataEdit component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  components: {
    CountryList: countryList
  },

  data: function () {
    return {
      pageName: "Details de facturation",
      userEnterprise: {},
      hasEnterprise: false,
      intTel1: null,
      intTel2: null,
    };
  },

  mounted() {
    waitLoader.show();
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    this.intTel1 = intlTelInput($("#userEnterpriseDataEditTel1")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });

    this.intTel2 = intlTelInput($("#userEnterpriseDataEditTel2")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });
    //
    //
    waitLoader.show();
    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
        waitLoader.hide();
      }, 1);
    },

    async getUserData() {
      //get All user data in json format
      let id = this.usersStore.User.account.id;
      id = functions.correctQueryString(id);
      //check if user has an interprise
      let h = this.usersStore.User.data.enterprise_id;
      this.hasEnterprise = h == null ? false : true;
      //
      try {
        const resp = await axios.get(Globals.website_url + "php/api.php?query=get-user-enterprise-by-id/" + id);
        this.userEnterprise = resp.data;
        //
        this.intTel1.setNumber(this.userEnterprise.tel1);
        this.intTel2.setNumber(this.userEnterprise.tel2);
      } catch (err) {
        // Handle Error Here
        waitLoader.hide();
        console.error(err);
        return;
      }
      //set user country
      $("#userEnterpriseDataEditCountry")[0].value = this.userEnterprise.country;
      waitLoader.hide();
    },

    update() {
      this.getUserData();
      //on enterprise edited
      $("#popupYes").click(() => {
        if (popup.action != "enterprise edited") return;
        //
        if (!this.hasEnterprise) {
          this.$router.push("/client/Home");
          return;
        }
        //
        waitLoader.hide();
      });

      //when player want to save new profil data
      $("#popupYes").click(() => {
        //in different popup action
        if (popup.action != "user-set-enterprise-data") return;

        //for this popup action
        popup.action = "";
        let id = functions.correctQueryString(this.usersStore.User.account.id);
        let name = functions.correctQueryString($("#userEnterpriseDataEditName")[0].value);
        let tel1 = functions.correctPhoneNumber(this.intTel1.getNumber());
        let tel2 = functions.correctPhoneNumber(this.intTel2.getNumber());
        let country = functions.correctQueryString($("#userEnterpriseDataEditCountry")[0].value);
        let city = functions.correctQueryString($("#userEnterpriseDataEditCity")[0].value);
        let address = functions.correctQueryString($("#userEnterpriseDataEditAddress")[0].value);
        //
        waitLoader.show();
        //to save new data
        axios
          .post(Globals.website_url + "php/api.php?query=set-user-enterprise-by-id/" + id + "/" + name + "/" + tel1 + "/" + tel2 + "/" + country + "/" + city + "/" + address)
          .then((response) => {
            let h = response.data;
            console.log(h);
            //on error
            if (h != "1") {
              waitLoader.hide();
              //say error
              popup.action = "Notification";
              popup.setTitle("Notification");
              popup.setMessage(
                "Erreur d'enregistrement des données!<br>Veuillez reéssayer."
              );
              popup.setButton("Ok");
              popup.show();
              return;
            }
            //on success
            Globals.clientMain.getUserData();
            setTimeout(() => {
              Globals.ClientleftMenu.hasEnterprise = true;
            }, 1000);
            //
            //
            this.getUserData();
            waitLoader.hide();
            //say password changed with succes
            popup.action = "enterprise edited";
            popup.setTitle("Notification");
            popup.setMessage("Vos informations ont été modifiées avec succès");
            popup.setButton("Ok");
            popup.show();
          })
          .catch((error) => {
            console.log(error);
            //
            waitLoader.hide();
          });
      });
      //
    },

    validate() {
      let name = $("#userEnterpriseDataEditName")[0].value;
      let tel1 = this.intTel1.getNumber();
      let tel2 = this.intTel2.getNumber();
      let country = $("#userEnterpriseDataEditCountry")[0].value;
      let city = $("#userEnterpriseDataEditCity")[0].value;
      let address = $("#userEnterpriseDataEditAddress")[0].value;
      //
      //check if there is at least one change
      //
      var onChange = false;
      //
      if (tel1 != this.userEnterprise.tel1) onChange = true;
      //
      if (tel2 != this.userEnterprise.tel2) onChange = true;
      //
      if (name != this.userEnterprise.name) onChange = true;
      //
      if (city != this.userEnterprise.city) onChange = true;
      //
      if (address != this.userEnterprise.home_address) onChange = true;
      //
      if (country != this.userEnterprise.country) onChange = true;
      //
      //check if there is a user data change
      //
      if (!onChange) {
        popup.action = "Notification";
        popup.setTitle("Notification");
        popup.setMessage("Aucune modification n'a été faite.");
        popup.setButton("Ok");
        popup.show();
        return;
      }
      //
      //
      //
      address = functions.correctQueryString(address);
      let error = false;
      let color = "#F66";
      //name errors
      if (name.length < 3) {
        document.getElementById(
          "userEnterpriseDataEditName"
        ).style.backgroundColor = color;
        error = true;
      }
      //city errors
      if (city.length < 3) {
        document.getElementById(
          "userEnterpriseDataEditCity"
        ).style.backgroundColor = color;
        error = true;
      }
      //address errors
      if (address.length < 3) {
        document.getElementById(
          "userEnterpriseDataEditAddress"
        ).style.backgroundColor = color;
        error = true;
      }
      //
      if (error) return;
      //
      popup.action = "user-set-enterprise-data";
      popup.setTitle("Question");
      popup.setMessage(
        "Voulez-vous vraiment enregistrer vos nouvelles informations ?"
      );
      popup.setButton("Oui", "Non");
      popup.show();
    },

    resetInput: function (e) {
      let color = "#FFF";
      e.target.style.backgroundColor = color;
    },
  }
};
</script>

<style scoped>
/*----------------------------------
USER DATA ENTERPRISE EDIT
----------------------------------*/
#userEnterpriseDataEdit {
  max-width: 768px;
  width: 100%;
  margin: auto;
  background-color: #F2FBFF;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  height: max-content;
  padding: 28px;
  color: #111a2b;
  text-align: left;
}

#userEnterpriseDataEdit form select {
  /**/
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  background-color: white;
}

#userEnterpriseDataEdit form button[type=submit] {
  margin-left: 12px;
  margin-top: 32px;
  border: none;
  border-radius: 3px;
  padding: 8px 24px;
  width: auto;
  box-sizing: content-box;
  color: white;
  background-color: #111a2b;
  font-size: 17px;
}

#userEnterpriseDataEdit form button[type=submit]:hover {
  color: white;
  background-color: #1b2842;
}

#userEnterpriseDataEdit form button[type=submit] i {
  color: rgba(123, 255, 0);
  font-size: 16px;
}

#userEnterpriseDataEdit form button[type=submit]:hover {
  background-color: #346;
}

@media only screen and (max-width:512px) {
  #userEnterpriseDataEdit {
    padding: 10px;
  }

  #userEnterpriseDataEdit form input,
  #userEnterpriseDataEdit form select {
    font-size: 12px !important;
  }
}
</style>