import OneSignal from "./OneSignal";

const __env__ = "prod";
const __dev_website_url__ = "http://localhost/";
const __prod_website_url__ = "https://client.ecodev.dev/";

var globals = {
    website_url: __env__ == "dev" ? __dev_website_url__ : __prod_website_url__,
    eppClient_url: "http://gateway.upay-bf.com/",
    upay_url: "https://gateway.upay-bf.com:3005/v1",

    clientMain: null,
    ClientleftMenu: null,
    clientHome: null,

    clientMainIsOk: false,
    phoneNumberConfirm: null,

    domain: {
        name: "",
        price: 0,
        reab: 0,
    },

    finalStep: false,


    AppVersion: "2024.1.0",
    //
    rDomainBF: {},
    //
    deferredPrompt: null,
    //
    chatFetchTime: 5000,
    notifsFetchTime: 5000,
    //
    notifPlayerID: null,
}

globals.cinet_notify_url = globals.website_url + "php/subscriptionOrderCardPayment.php";
globals.cinet_return_url = globals.website_url + "#/client/Subscriptions/services";
globals.cinet_apikey = "108320773565f879e7749091.94478800";

export default globals;