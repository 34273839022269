<template>
  <main id="userData">
    <h1 id="userDataTitle">{{ pageName }}</h1>

    <div id="userDataTabs">
      <div id="userDataTabsBox">
        <router-link id="userDataInfosVue" to="/client/UserData/Infos" class="userDataTabItem"
          @click="selectUserDataTab">
          <span class="userDataTab">
            <i class="bi bi-person-fill"></i>
            <a>Informations personnelles</a>
            <b>Infos Client</b>
          </span>
        </router-link>

        <router-link to="/client/UserData/EntrepriseData" class="userDataTabItem" @click="selectUserDataTab">
          <span class="userDataTab">
            <i class="bi bi-file-earmark-ruled-fill"></i>
            <a>Details de Facturation</a>
            <b>Facturation</b>
          </span>
        </router-link>
      </div>

      <div id="userDataTabsLine">
        <div id="userDataTabsCursor" ids="0"></div>
      </div>
    </div>

    <router-view></router-view>
  </main>
</template>



<script>
const $ = require("jquery");
import Globals from "/src/common/js/Globals";
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";


//UserData component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      pageName: "",
    };
  },

  mounted() {
    setTimeout(() => {
      let path = this.$route.path;
      if (this.$route.path == "/client/UserData/EditData") {
        path = "/client/UserData/Infos";
      }
      else if (this.$route.path == "/client/UserData/EditPassword") {
        path = "/client/UserData/Infos";
      }
      if (path != "/client/UserData/Infos") {
        this.forceUserDataTab(document.querySelector("a[href='#" + path + "']"));
      }
    }, 500);
    //
    Globals.UserData = this;
    //
    this.update();
  },

  methods: {
    update() {
      //on resizing
      $(window).resize(function () {
        try {
          //set cursor tab at correct position
          let objs = document.getElementsByClassName("userDataTabItem");
          let ids = document.getElementById("userDataTabsCursor").getAttribute("ids");
          let o = objs[ids];
          //
          let w = o.getBoundingClientRect().right - o.getBoundingClientRect().left;
          $("#userDataTabsCursor").css("width", w + "px");
          let l = o.getBoundingClientRect().left - $("#userDataTabs")[0].getBoundingClientRect().left;
          $("#userDataTabsCursor").css("left", l + "px");
        }
        catch (err) {

        }
      });
    },

    updateTabs() {
      //set cursor tab at correct position
      let obj = document.querySelector(".userDataTabItem.router-link-exact-active");
      let w = obj.getBoundingClientRect().right - obj.getBoundingClientRect().left;
      let l = obj.getBoundingClientRect().left - document.querySelector("#userDataTabs").getBoundingClientRect().left;
      //
      $("#userDataTabsCursor").css("width", w + "px");
      $("#userDataTabsCursor").css("left", l + "px");
    },


    selectUserDataTab(e) {
      let o = e.target.parentElement;
      this.forceUserDataTab(o);
    },

    forceUserDataTab(o) {
      //set cursor tab at correct position
      let objs = document.getElementsByClassName("userDataTabItem");
      let ids = -1;
      for (let i = 0; i < objs.length; i++) {
        if (o == objs[i]) {
          ids = i;
          break;
        }
      }
      $("#userDataTabsCursor").attr("ids", ids);
      //
      let w = o.getBoundingClientRect().right - o.getBoundingClientRect().left;
      $("#userDataTabsCursor").css("width", w + "px");
      let l = o.getBoundingClientRect().left - $("#userDataTabs")[0].getBoundingClientRect().left;
      $("#userDataTabsCursor").css("left", l + "px");
    }
  }
};
</script>



<style scoped>
#userData {
  color: black;
  width: 100%;
  position: relative;
  padding: 24px 12px;
}

#userDataTitle {
  margin-bottom: -32px;
}

#userDataTabs {
  max-width: 840px;
  width: 100%;
  margin: auto;
  margin-top: 64px;
  font-size: 16px;
  text-align: left;
  margin-bottom: 32px;
}

.userDataTabItem {
  width: max-content;
  margin-right: 32px;
}

.userDataTab {
  width: max-content;
  cursor: pointer;
  color: black;
}

.userDataTab:hover {
  color: white;
}

.userDataTab i {
  font-size: 20px;
  margin-right: 8px;
}

.userDataTab b {
  display: none;
}

#userDataTabs .router-link-exact-active {
  color: white;
}

#userDataTabs .router-link-exact-active i {
  color: white;
}

#userDataTabs .router-link-exact-active a {
  color: white;
}

#userDataTabs .router-link-exact-active b {
  color: white;
}

#userDataTabsLine {
  margin-top: 8px;
  background-color: #111a2b;
  width: 100%;
  height: 2px;
}

#userDataTabsCursor {
  width: 0px;
  background-color: white;
  height: 5px;
  position: relative;
  top: -1px;
  left: 0;
  transition: all 0.25s ease;
  border-radius: 3px;
}


/*---------------------------------
media queries
---------------------------------*/
@media only screen and (max-width:1128px) {
  .userDataTab a {
    display: none;
  }

  .userDataTab b {
    display: inline;
  }

  .userDataTabItem {
    margin-right: 16px;
  }
}

@media only screen and (max-width:600px) {
  .userDataTab b {
    display: none;
  }

  .userDataTab i {
    margin-left: 16px;
  }

  .userDataTabItem {
    margin-right: 8px;
  }
}
</style>