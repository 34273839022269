<template>
  <div id="bg"></div>

  <center>
    <form class="row mt-4" @submit.prevent="validate();">
      <div class="text-center">

      </div>
      <h4 class="text-center mb-3">Informations du nom de domaine .bf</h4>
      <!--  -->

      <div class="__EC_domainBox">
        <input id="__EC_domain" placeholder="Entrez votre nom de domaine..." required v-model="domain" />
        <button type="submit">
          <a id="__EC_btn_text">VÉRIFIER</a>
          <svg id="__EC_spin" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#fff" viewBox="0 0 16 16">
            <path
              d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
            <path fill-rule="evenodd"
              d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
          </svg>
        </button>
      </div>

      <br />
      <br />
      <br />

      <div class="mb-3" v-if="info">
        <div class="info">
          <div class="info-item">
            <a>Création: </a>
            <b>{{ info.created_at }}</b>
          </div>

          <div class="info-item">
            <a>Mise à jour: </a>
            <b>{{ info.updated_at }}</b>
          </div>

          <div class="info-item">
            <a>Expiration: </a>
            <b>{{ info.expire_at }}</b>
          </div>
        </div>

        <div class="text-center">
          <img src="/logo-regsitre-bf.png" />
          <h1 style="color:#c60000;">Transférer votre nom de domaine (.bf)</h1>

          <br>

          <h3>Procédure:</h3>
          <p>
          <h5>
            1. Remplissez et validez le formulaire (<a
              href="https://abdi.bf/formulaire-de-demande-de-transfert-de-bureau-denregistrement/" target="_blank"
              class="fw-bold">cliquez ici</a>)
            Tout en renseignant les informations suivantes dans la section "CHOIX DE L'AGENT D'ENREGISTREMENT":
            <br>
            <br>
            Dénomination: ECODEV INTERNATIONAL
            <br>
            E-mail: bf@ecodev.dev
            <br>
            Téléphone: +226 56343412
          </h5>
          </p>

          <p>
          <h5>
            2. Informez nous par email : <a href="mailto:support@ecodev.dev">support@ecodev.dev</a>
            <br>
            Ou par whatsapp au numéro : <a href="https://wa.me/+22656343412" target="_blank">+226 56 34 34 12</a>
          </h5>
          </p>

          <p>
          <h5>
            3. Nous nous chargeons du reste!
          </h5>
          </p>
        </div>
      </div>

      <div class="__EC_domainStateError" v-else>
        Pas d'information disponible.
      </div>
    </form>

  </center>
</template>


<script>
const $ = require("jquery");
import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
import functions from "/src/common/js/functions";
import axios from "axios";
import popup from "/src/common/js/popup";
import moment from "moment";

//login component
export default {
  data() {
    return {
      domain: "",
      info: null,
    };
  },

  mounted() {

  },

  methods: {
    validate() {
      waitLoader.show();

      this.domain = this.domain.toLowerCase();

      setTimeout(async () => {
        try {
          const resp = await axios.get(Globals.website_url + "php/api.php?query=get-domain-infos-simple/" + this.domain);
          let h = resp.data;

          if (h == "") {
            waitLoader.hide();
            this.info = null;
            return;
          }

          h = JSON.parse(h);

          if (h.success) {
            let data = h.data;
            this.info = {};
            this.info.created_at = moment(data.created_at).format("DD/MM/YYYY HH:mm:ss");
            this.info.updated_at = moment(data.updated_at).format("DD/MM/YYYY HH:mm:ss");
            this.info.expire_at = moment(data.expire_at).format("DD/MM/YYYY HH:mm:ss");
            waitLoader.hide();
          }
          else {
            this.info = null;
            waitLoader.hide();
          }
        } catch (err) {
          // Handle Error Here
          this.info = null;
          console.error(err);
          waitLoader.hide();
        }
      }, 100);
    },
  }
  //
};
</script>

<style scoped>
@import 'intl-tel-input/build/css/intlTelInput.css';

#bg {
  background: url("/src/assets/images/app-bg.jpg") no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


/*----------------------------------
USER DATA EDIT
----------------------------------*/
form {
  max-width: 920px;
  width: 100%;
  background-color: #F2FBFF;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  text-align: left;
  color: #111a2b;
  padding: 16px;

  @media only screen and (max-width:380px) {
    padding: 8px;
  }
}

img {
  width: 200px;
  height: auto;
}

.info {
  background-color: white;
  border-radius: 6px;
  padding: 8px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width:380px) {
    display: block;
  }
}

.info-item {
  display: inline-block;
  margin: 2px 2px;
}

/*  */
/*  */
/*  */
/*  */
/*  */
input,
button:focus,
textarea:focus,
select:focus {
  outline: none;
}

.__EC_domainBox {
  width: 100% !important;
  display: flex !important;
  height: 54px !important;
}

.__EC_domainBox button {
  width: 160px !important;
  height: 100% !important;
  padding: 0px 24px !important;
  background-color: #ff643f !important;
  box-shadow: -100px 0px 68px -1px #fe575b inset !important;
  border: none !important;
  margin-left: 8px !important;
  border-radius: 15px !important;
  cursor: pointer !important;
}

.__EC_domainBox button:hover {
  background-color: #fe575b !important;
  box-shadow: -100px 0px 68px -1px #ff643f inset !important;
}

#__EC_btn_text {
  font-size: 13px !important;
  font-family: tahoma !important;
  color: white !important;
}

#__EC_spin {
  display: none;
  animation-name: __EC_spin !important;
  animation-duration: 1000ms !important;
  animation-iteration-count: infinite !important;
  animation-timing-function: linear !important;
}

@keyframes __EC_spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.domainData {
  height: 32px !important;
}

#reserveDomainForm {
  background-color: white;
  padding: 32px 16px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
}

.__EC_domainBox input {
  flex-grow: 100 !important;
  height: 100% !important;
  padding: 16px 24px !important;
  background-color: rgb(231, 244, 255) !important;
  font-size: 16px !important;
  overflow: hidden !important;
  font-family: tahoma !important;
  border-radius: 15px !important;
  border: none !important;
}

.__EC_buy {
  float: right !important;
  width: 160px !important;
  height: 48px !important;
  padding: 0px 24px !important;
  background-color: #61ce70 !important;
  box-shadow: -100px 0px 68px -1px #3ea55c inset !important;
  border: none !important;
  border-radius: 15px !important;
  cursor: pointer !important;
  font-family: tahoma !important;
  font-size: 13px !important;
  color: white !important;
}

.__EC_buy:hover {
  background-color: #3ea55c !important;
  box-shadow: -100px 0px 68px -1px #61ce70 inset !important;
}

#__EC_domainStateBox {
  display: none;
  text-align: left;
  width: 100%;
}

#__EC_domainState {
  font-size: 16px !important;
  font-family: tahoma !important;
  width: 75%;
  height: max-content;
}

#__EC_domainText {
  display: block !important;
}

#__EC_domainText2 {
  display: block !important;
}

.__EC_domainStateValid,
.__EC_domainStateError {
  float: left;
  position: relative;
  color: #b00;
  font-size: 20px !important;
  font-family: tahoma !important;
}

.domainTrans {
  float: left;
  margin-top: 4px;
  margin-left: 6px;
  color: black;
  width: calc(100% - 40px) !important;
}

.domainTrans div {
  float: left !important;
  display: inline-block !important;
  max-width: 100% !important;
}

.domainTrans span {
  float: left !important;
  margin-top: 0px !important;
}

.domainFree {
  float: left;
  margin-top: 4px;
  margin-left: 6px;
  color: black;
  width: calc(100% - 40px) !important;
}

.domainFree div {
  float: left !important;
  display: inline-block !important;
  max-width: 100% !important;
}

.domainFree span {
  float: left !important;
  margin-top: 0px !important;
}

.domainNotFree {
  float: left;
  margin-left: 6px;
  color: black;
}

.__EC_domErr {
  position: relative;
  top: 4px;
  color: #b00;
}

.stroked-price {
  margin-right: 8px;
}

.star-badge {
  margin-left: 8px;
  display: inline;
  border-radius: 10px;
  padding: 4px 10px;
  color: white;
  font-size: 15px;
  background-color: #dc3545;
  width: max-content;
  height: max-content;
}

@media only screen and (max-width: 643px) {
  #reserveDomainForm {
    padding: 8px !important;
  }

  .__EC_domainBox {
    display: block !important;
    height: max-content !important;
  }

  .__EC_domainBox input {
    display: block;
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 8px !important;
    height: 48px !important;
  }

  .__EC_domainBox button {
    width: 100% !important;
    margin: 0 !important;
    height: 48px !important;
  }

  #__EC_domainStateBox {
    float: none !important;
    width: 100% !important;
    height: max-content !important;
  }

  #__EC_domainState {
    font-size: 16px !important;
    font-family: tahoma !important;
    width: 100%;
    height: max-content;
  }

  .__EC_domainStateValid,
  .__EC_domainStateError {}

  .domainInfos {
    display: block !important;
    width: 100% !important;
  }

  #__EC_domainText {
    display: inline-block !important;
    margin-bottom: 16px !important;
  }

  #__EC_domainText2 {
    display: inline-block !important;
    margin-bottom: 16px !important;
  }

  .__EC_buy {
    display: block !important;
    float: none !important;
    width: 100% !important;
  }
}
</style>