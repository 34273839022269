<template>
  <main id="mess" class="text-start" v-if="subs.length >= 1">
    <div id="allDomainFilter" class="mb-2">
      <a>Nom de domaine</a>
      <br class="d-none">
      <select id="allDomainFilterSelect" class="ms-2" @change="__update()">
        <option v-for="(sub, index) in subs" :key="index">{{ sub.domain_name }}</option>
      </select>
    </div>

    <div class="" v-show="curSubs.emailAccountCount == 0 || invalidEmailApiAccess">
      <h4>Ce nom de domaine n'a pas accès à la Messagerie.</h4>
    </div>
    <!-- -->
    <!--  -->
    <!--  -->
    <div class="" v-show="moment(curSubs.exp_date) <= currentDate()">
      <h4>Ce nom de domaine a expiré, veuillez le renouveller pour avoir accès à la messagerie.</h4>
    </div>

    <div v-show="moment(curSubs.exp_date) > currentDate() && !invalidEmailApiAccess">
      <div class="w-100" id="messHeader">
        <a id="messMessage" class="float-start text-dark">Créer et Gérer vos adresses mails.</a>
        <!--  -->
        <br class="d-none">
        <!--  -->
        <div id="messMailCount" class="float-end">
          <span class="float-start me-1">
            <a>Emails: </a><strong>
              {{ emails.length + " / " + (totalMailCount == "-" ? "-" : (totalMailCount >= 0 ? totalMailCount :
                "illimité")) }}
            </strong>
          </span>
          <!--  -->
          <span class="float-end">
            <a> - Stockage(GB): </a><strong>{{ totalEmailAccountUsedQuotaG + " / " + emailTotalQuotaG }}</strong>
          </span>
        </div>
      </div>


      <div id="messSearch" class="w-100">
        <div class="float-start">
          <button id="subsUpdateBtn" type="button"
            class="d-inline-block refreshBtn form-control btn-success border-success w-auto me-2 mb-2"
            @click="forceUpdate()" enable="true">
            Actualiser <i class="bi bi-arrow-clockwise text-white"></i>
          </button>
          <div id="messSearchBox" class="input-group mb-2 d-inline-flex">
            <i class="input-group-text bi bi-search"></i>
            <input id="filterInput" type="search" class="form-control" placeholder="Recherche..."
              @input="dtSetFilter" />
          </div>
          <!--  -->

          <div class="field input-group mb-2 d-inline-flex">
            <span class="bi bi-link-45deg text-center input-group-text"></span>

            <input id="copyLinkInput" class="form-control" type="text" :value="curSubs.domain_name + ':2096'">

            <button @click="copyLink" class="btn btn-primary input-group-text">Copier</button>

            <a class="btn btn-secondary input-group-text" :href="'https://' + this.curSubs.domain_name + ':2096'"
              target="_blank">
              <i class="bi-box-arrow-up-right"></i>
            </a>
          </div>
        </div>

        <div id="messMenuBtn" class="float-end">
          <button v-if="emails.length < totalMailCount || totalMailCount == -1" id="subsCreateBtn" type="button"
            class="form-control btn-primary border-primary ms-2" @click="createMail()">
            <i class="bi bi-plus-circle text-white"></i> Créer un nouveau compte Mail
          </button>

          <button v-else type="button" class="form-control btn-warning border-warning ms-2" @click="upgradeOffer()">
            <i class="bi bi-plus-circle-dotted text-dark"></i> Augmenter le quota de comptes Mail
          </button>
        </div>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <div v-show="screenWidth <= smallScreen" class="float-start w-100">
        <center>
          <div class="manageServicesItem row w-100 text-center" v-for="(email, index) in dtPageData" :key="index">
            <div class="manageServicesTitle">
              <a class="col-sm-8 col-12">{{ email.email }}</a>
            </div>
            <div class="manageServicesSubItem p-2">
              <div class="manageServicesSubitem">
                <b>Stockage : {{ email.quotaPercent }}% / {{ bToM(email.quota) }} MB</b>
                <span class="bar">
                  <span class="barValue" :val="email.quotaPercent"></span>
                </span>
              </div>

              <div class="text-center w-100">
                Status :
                <a class="bg-secondary fw-normal text-white state" v-if="email.suspended_login == 1">Desactivé</a>
                <a class="bg-success fw-normal text-white state" v-else>Activé</a>
              </div>


              <div class="text-center mt-4">
                <button class="btn btn-secondary mt-2" @click="curEmail = email; openMenu();">
                  <i class="bi bi-gear-fill me-2"></i>Gérer
                </button>
              </div>
            </div>
          </div>
        </center>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <div id="messHomeBoard" class="w-100 float-start mb-5" v-show="screenWidth > smallScreen && emails.length >= 1">
        <div class="card-header text-start">
          <i class="bi bi-table"></i>
          Comptes de messagerie
        </div>

        <div class="d-flex justify-content-between mt-2">
          <label class="ms-3 mr-auto pt-2">
            <select id="dataCountPerPageList" @change="dtSetDataCountPerPage">
              <option value="5">5</option>
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
            Données par Page
          </label>
        </div>

        <table id="messDataTable" class="dtTable table table-sm">
          <thead>
            <tr>
              <th class="text-center">
                <div dt_column="id" dt_order="ASC">
                  <a>N°</a>
                  <i class="bi bi-arrow-up homeDataTableSortIcon dt_up"></i>
                  <i class="bi bi-arrow-down homeDataTableSortIcon dt_down"></i>
                </div>
              </th>
              <th class="text-center">
                <div dt_column="usermail">
                  <a>Compte de messagerie</a>
                  <i class="bi bi-arrow-up homeDataTableSortIcon dt_up"></i>
                  <i class="bi bi-arrow-down homeDataTableSortIcon dt_down"></i>
                </div>
              </th>
              <th class="text-center">
                <div dt_column="stockage">
                  <a>Stockage: Utilisé / Total / %</a>
                  <i class="bi bi-arrow-up homeDataTableSortIcon dt_up"></i>
                  <i class="bi bi-arrow-down homeDataTableSortIcon dt_down"></i>
                </div>
              </th>
              <th class="text-center">
                <div dt_column="status">
                  <a>Status</a>
                  <i class="bi bi-arrow-up homeDataTableSortIcon dt_up"></i>
                  <i class="bi bi-arrow-down homeDataTableSortIcon dt_down"></i>
                </div>
              </th>
              <th class="text-center">
                <div>
                  <a>Action</a>
                  <i class="bi bi-arrow-up homeDataTableSortIcon dt_up"></i>
                  <i class="bi bi-arrow-down homeDataTableSortIcon dt_down"></i>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr class="dataItem" v-for="(email, index) in dtPageData" :key="index">

              <td>{{ email.item_id + 1 }}</td>

              <td>
                {{ email.email }}
              </td>

              <td>
                <b>{{ bToM(email.usedQuota) }} MB / {{ bToM(email.quota) }} MB / {{ email.quotaPercent }}%</b>
                <span class="bar">
                  <span class="barValue" :val="email.quotaPercent"></span>
                </span>
              </td>

              <td>
                <a class="bg-secondary fw-normal text-white" v-if="email.suspended_login == 1">Desactivé</a>
                <a class="bg-success fw-normal text-white" v-else>Activé</a>
              </td>

              <td>
                <button class="btn btn-outline-success table-btn" @click="unsuspendMail"
                  v-if="email.suspended_login == 1" :email="email.email">
                  Activer
                </button>
                <button class="btn btn-outline-secondary table-btn" @click="suspendMail" :email="email.email" v-else>
                  Desactiver
                </button>
                <!--  -->
                <button class="btn btn-secondary table-btn" @click="passwordMail" :ids="email.item_id">
                  Mot de passe
                </button>
                <button class="btn btn-primary table-btn" id="manageMailBtn" @click="manageMail"
                  :ids="email.item_id">Gérer</button>
              </td>
            </tr>
          </tbody>
        </table>

        <center>
          <ul id="pageBar" class="btn-group" show="false">
            <li id="pageSL" @click="dtFirstPage()" class="fPager btn btn-outline-primary">&laquo;</li>
            <li id="pageL" @click="dtPreviousPage()" class="pager btn btn-outline-primary">&#x3C;</li>
            <!--  -->
            <li id="pageFirst" @click="dtShowPage" class="pageBtn btn btn-primary">1</li>
            <li id="pageSecond" @click="dtShowPage" class="pageBtn btn btn-outline-primary">2</li>
            <li id="pageEllipsis" @click="dtShowPage" class="pageBtn btn btn-outline-primary">3</li>
            <li id="pageLast" @click="dtShowPage" class="pageBtn btn btn-outline-primary">4</li>
            <!--  -->
            <li id="pageR" @click="dtNextPage()" class="pager btn btn-outline-primary">&#x3E;</li>
            <li id="pageSR" @click="dtLastPage()" class="fPager btn btn-outline-primary">&raquo;</li>
          </ul>
        </center>
      </div>


      <center>
        <div id="noMail" v-if="emails.length === 0">
          <div class="message-container">
            <h3 class="float-start  d-block w-100 text-center">Aucun compte à afficher</h3>
            <p class="float-start  d-block w-100 text-center">Il n'y a actuellement aucun compte mail à afficher.</p>
            <!--  -->
            <button v-if="emails.length < totalMailCount || totalMailCount == -1" id="subsCreateBtn" type="button"
              class="btn btn-primary border-primary w-auto ms-2" @click="createMail()">
              <i class="bi bi-plus-circle text-white"></i> Créer un nouveau compte Mail
            </button>
          </div>
        </div>
      </center>

    </div>
    <!--  -->
    <!--  -->
  </main>



  <div class="modal" id="menuModalBack" @click="closeMenu">
    <div class="modal-dialog" id="menuModal">
      <div class="modal-content menuBody">
        <div v-show="curEmail.suspended_login" class="w-100 p-2" @click="unsuspendMail" id="unsuspendMenuBtn" email="">
          Activer
        </div>
        <div v-show="!curEmail.suspended_login" class="w-100 p-2" @click="suspendMail" id="suspendMenuBtn" email="">
          Désactiver
        </div>
        <div class="w-100 p-2 border-top" @click="callPasswordMail()">
          Mot de passe
        </div>
        <div class="w-100 p-2 border-top" @click="callManageMail()">
          Stockage
        </div>
        <div class="w-100 p-2 border-top" @click="callDeleteMail()">
          Supprimer
        </div>
      </div>
    </div>
  </div>



  <div class="modal" id="messCreateModalBack">
    <div class="modal-dialog" id="messCreateModal">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Créer un nouveau compte</h5>
          <button type="button" class="btn-close closeBtn" modalId="messCreateModalBack"></button>
        </div>
        <form @submit.prevent="validateCreateMail()">
          <!--  -->
          <div class="modal-body">
            <div id="messCEmail" class="input-group mb-3 float-start">
              <input type="text" class="form-control" placeholder="Nom d'utilisateur..." id="messCUsernameInput"
                required>
              <a class="input-group-text">@{{ curDomainName }}</a>
            </div>
            <!--  -->
            <div class="useMailChecker float-start mb-3">
              <input class="form-check-input me-2" type="checkbox" id="useMailForPassword" v-model="useMailCheck2">
              <label class="form-check-label" for="useMailForPassword">
                Recevoir un mail pour le mot de passe
              </label>
            </div>
            <div v-show="useMailCheck2">
              <div class="input-group mb-3 float-start" id="messEmail">
                <a class="input-group-text">
                  Adresse email
                </a>
                <input type="text" class="form-control" id="messPMailInput2" ids="">
              </div>
              <div class="float-start text-start mb-3">
                <a class="importantSentence">
                  * Un e-mail pour la réinitialisation du mot de passe de ce
                  compte sera envoyé à l'adresse saisie. *
                </a>
              </div>
            </div>
            <!--  -->
            <div v-show="!useMailCheck2">
              <div id="messCPassword" class="input-group mb-2 float-start">
                <a class="input-group-text">Mot de passe</a>
                <input type="text" class="form-control" id="messCPasswordInput">
              </div>
              <div class="float-start text-start mb-3">
                <a class="importantSentence">
                  * Recommandation: Le mot de passe doit avoir au moins une longueur de 5 caractère
                  et doit contenir des symboles, des majuscules, des minuscules et des chiffres.*
                </a>
              </div>
            </div>
            <div id="messData" class="input-group mb-3 float-start">
              <input type="number" class="form-control" placeholder="Stockage..." id="messCQuotaInput" min="0"
                :max="emailTotalQuota" @input="checkInputNumber" required>
              <a class="input-group-text">MB</a>
            </div>
          </div>
          <!--  -->
          <div class="modal-footer">
            <button type="button" class="closeBtn btn btn-secondary w-25" modalId="messCreateModalBack">Annuler</button>
            <button type="submit" class="closeBtn btn btn-primary w-25"
              modalId="messCreateModalBack">Enregistrer</button>
          </div>
        </form>
      </div>
    </div>
  </div>




  <div class="modal" id="messPasswordModalBack">
    <div class="modal-dialog" id="messPasswordModal">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Changer de mot de passe</h5>
          <button type="button" class="btn-close closeBtn" modalId="messPasswordModalBack"></button>
        </div>
        <form @submit.prevent="validatePasswordMail()">
          <!--  -->
          <div class="modal-body">
            <div id="messEmail" class="input-group mb-3 float-start">
              <a class="input-group-text">
                Compte email
              </a>
              <input type="text" class="form-control" id="messPUsernameInput" disabled ids="">
            </div>
            <!--  -->
            <div class="useMailChecker float-start mb-3">
              <input class="form-check-input me-2" type="checkbox" id="useMailForPassword2" v-model="useMailCheck">
              <label class="form-check-label" for="useMailForPassword2">
                Recevoir un mail pour le mot de passe
              </label>
            </div>
            <div v-show="useMailCheck">
              <div class="input-group mb-3 float-start" id="messEmail">
                <a class="input-group-text">
                  Adresse email
                </a>
                <input type="text" class="form-control" id="messPMailInput" ids="">
              </div>
              <div class="float-start text-start mb-3">
                <a class="importantSentence">
                  * Un e-mail pour le changement du mot de passe de ce
                  compte sera envoyé à l'adresse saisie.*
                </a>
              </div>
            </div>
            <!--  -->
            <div v-show="!useMailCheck">
              <div id="messPassword" class="input-group mb-2 float-start">
                <a class="input-group-text">
                  Mot de passe
                </a>
                <input type="password" class="form-control" id="messPPasswordInput">
              </div>
              <div class="float-start text-start mb-3">
                <a class="importantSentence">
                  * Recommandation: Le mot de passe doit avoir au moins une longueur de 5 caractères
                  et doit contenir des symboles, des majuscules, des minuscules et des chiffres.*
                </a>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="modal-footer">
            <button type="button" class="closeBtn btn btn-secondary w-25"
              modalId="messPasswordModalBack">Annuler</button>
            <button type="submit" class="closeBtn btn btn-primary w-25" modalId="messPasswordModalBack">Valider</button>
          </div>
        </form>
      </div>
    </div>
  </div>




  <div class="modal" id="messManageModalBack">
    <div class="modal-dialog" id="messManageModal">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Gérer compte Mail</h5>
          <button type="button" class="btn-close closeBtn" modalId="messManageModalBack"></button>
        </div>
        <form @submit.prevent="validateManageMail()">
          <!--  -->
          <div class="modal-body">
            <div id="messEmail" class="input-group mb-3 float-start">
              <input type="text" class="form-control" id="messMUsernameInput" disabled>
            </div>

            <div id="messData" class="input-group mb-3 float-start">
              <a class="input-group-text">Stockage</a>
              <input type="number" class="form-control" placeholder="Stockage..." id="messMQuotaInput" min="0"
                :max="emailTotalQuota" @input="checkInputNumber" required>
              <a class="input-group-text">MB</a>
            </div>
          </div>
          <!--  -->
          <div class="modal-footer">
            <button type="button" class="closeBtn btn btn-danger w-auto" modalId="messManageModalBack"
              @click="validateDeleteMail()">Supprimer</button>
            <button type="button" class="closeBtn btn btn-secondary w-auto"
              modalId="messManageModalBack">Annuler</button>
            <button type="submit" class="closeBtn btn btn-primary w-auto"
              modalId="messManageModalBack">Enregistrer</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
const $ = require("jquery");
import Globals from "/src/common/js/Globals";
import functions from "/src/common/js/functions";
import axios from "axios";
import waitLoader from "/src/common/js/waitLoader";
import popup from "/src/common/js/popup";
//
import moment from "moment";
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";

//login component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      screenWidth: 768,
      smallScreen: 800,
      //
      pageName: "Messagerie Professionnelle",
      hasEnterprise: false,
      isReady: false,
      //
      subs: [],
      curSubs: {},
      serverTime: null,
      //
      totalMailCount: "-",
      useMailCount: "-",
      freeMailCount: "-",
      curDomainName: "--------",
      emails: [],
      //
      dtFilterValue: "",
      dtPageData: [],
      dtPageCount: 1,
      dtDataCountPerPage: 10,
      dtCurPage: 0,
      //
      emailTotalQuota: "-",
      emailTotalQuotaG: "-",
      totalEmailAccountQuota: "-",
      totalEmailAccountUsedQuota: "-",
      totalEmailAccountUsedQuotaG: "-",
      //
      useMailCheck: false,
      useMailCheck2: false,
      //
      invalidEmailApiAccess: false,
      //
      curEmail: {},
    };
  },

  mounted() {
    setTimeout(() => {
      this.getScreenWidth();
    }, 1);
    //
    //get server time
    this.serverTime = this.getServerTimeInMs();
    //
    $("#routeName")[0].innerHTML = this.pageName;
    //
    $(".closeBtn").click(function () {
      $("#" + this.getAttribute("modalId")).css("display", "none");
    });
    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }

    $("[dt_column]").click(function () {
      let order = this.getAttribute("dt_order");
      if (order == null) {
        document.querySelectorAll("[dt_column]").forEach(function (item) {
          if (item != this) item.removeAttribute("dt_order");
        });
        //
        this.setAttribute("dt_order", "ASC");
      } else if (order == "ASC") {
        this.setAttribute("dt_order", "DESC");
      } else if (order == "DESC") {
        this.setAttribute("dt_order", "ASC");
      }
      //
      $this.dtRefreshTable();
      waitLoader.hide();
    });
  },

  methods: {
    getScreenWidth() {
      setTimeout(() => {
        this.screenWidth = innerWidth;
        this.getScreenWidth();
      }, 1);
    },

    copyLink(e) {
      let input = document.querySelector('#copyLinkInput');
      input.select();
      if (document.execCommand("copy")) {
        e.target.innerText = 'Copié !';
        setTimeout(() => {
          e.target.innerText = 'Copier';
          $(input).blur();
        }, 500);
      }
    },

    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
      }, 1);
    },

    async update() {
      //get All user data in json format
      let id = this.usersStore.User.account.id;
      id = functions.correctQueryString(id + "");
      try {
        const resp = await axios.get(Globals.website_url + "php/api.php?query=get-user-active-offers-by-id/" + id);
        this.subs = [];
        //
        resp.data.forEach((item) => {
          if (item.emailAccountCount >= 1 || item.emailAccountCount == -1) {
            this.subs.push(item);
          }
        });
        //
        if (this.subs.length === 0) {
          this.$router.push('/client/Home');
          return;
        }
        //
        setTimeout(() => {
          this.forceUpdate();
        }, 1);
      } catch (err) {
        // Handle Error Here
        console.error(err);
        waitLoader.hide();
        return;
      }
    },

    forceUpdate() {
      if ($("#allDomainFilterSelect").val() == null) {
        return;
      }
      //
      this.curDomainName = $("#allDomainFilterSelect").val().toLowerCase();
      //
      this.subs.forEach((item) => {
        if (item.domain_name == this.curDomainName) {
          this.curSubs = item;
        }
      });
      //
      waitLoader.show();
      this.emails = [];
      //
      this.subs.forEach((item, index) => {
        if (item.domain_name == this.curDomainName) {
          this.totalMailCount = this.subs[index].emailAccountCount;
          this.emailTotalQuota = this.subs[index].emailTotalQuota;
          this.emailTotalQuotaG = this.subs[index].emailTotalQuota / (1024);
        }
      });
      //
      this.updateList(this.curDomainName);
    },

    async updateList(domain) {
      try {
        let resp = await axios.get(Globals.website_url + "php/emailApi/api.php?query=email-list-with-disk-usage/" + domain);
        console.log(resp.data);
        if (resp.data == "" || resp.data.errors !== null) {
          console.log(resp.data);
          this.invalidEmailApiAccess = true;
          this.emails = [];
          //
          let $this = this;
          //
          waitLoader.hide();
          return;
        }
        //
        this.invalidEmailApiAccess = false;
        this.emails = resp.data.data;
        //
        this.totalEmailAccountQuota = 0;
        this.totalEmailAccountUsedQuota = 0;
        //
        let userList = [];
        this.emails.forEach((item, index) => {
          item.item_id = index;
          item.status = item.suspended_login == 0 ? "activé" : "désactivé";
          userList.push(item.email);
          //
          item.quota = item._diskquota;//en Megabytes
          item.usedQuota = item.diskused * 1024 * 1024;//en bytes
          item.quotaPercent = this.round(item.diskusedpercent_float, 0.01);
          //
          this.totalEmailAccountQuota = this.totalEmailAccountQuota - (- item.quota);
          this.totalEmailAccountUsedQuota = this.totalEmailAccountUsedQuota - (- item.usedQuota);
        });
        //
        this.totalEmailAccountUsedQuotaG = this.round(this.bToG(this.totalEmailAccountUsedQuota), 0.01);
        //
        this.totalEmailAccountQuota = this.bToM(this.totalEmailAccountQuota);
        this.totalEmailAccountUsedQuota = this.bToM(this.totalEmailAccountUsedQuota);
        //
        //Get accounts total count
        userList = JSON.stringify(userList);
        //
        this.dtRefreshTable();
        waitLoader.hide();
        //
        //order by
        let $this = this;
        //
      } catch (err) {
        // Handle Error Here
        waitLoader.hide();
        console.error(err);
      }
    },

    dtRefreshTable() {
      $("#pageBar").attr("show", false);
      this.dtMakePage(this.emails);
      $("#pageBar").attr("show", true);
    },

    dtSetDataCountPerPage(e) {
      this.dtDataCountPerPage = e.target.value;
      this.dtCurPage = 0;
      this.dtRefreshTable();
    },

    dtSetFilter(e) {
      this.dtFilterValue = e.target.value;
      this.dtCurPage = 0;
      this.dtRefreshTable();
    },

    dtShowPage(e) {
      if (e.target.innerText == "...") return;
      //
      this.dtCurPage = e.target.innerText.trim() - 1;
      this.dtRefreshTable();
    },

    dtFirstPage() {
      this.dtCurPage = 0;
      this.dtRefreshTable();
    },

    dtPreviousPage() {
      this.dtCurPage = Math.max(this.dtCurPage - 1, 0);
      this.dtRefreshTable();
    },

    dtNextPage() {
      this.dtCurPage = Math.min(this.dtCurPage + 1, this.dtPageCount - 1);
      this.dtRefreshTable();
    },

    dtLastPage() {
      this.dtCurPage = this.dtPageCount - 1;
      this.dtRefreshTable();
    },

    dtMakePage(data) {
      //calculate page count
      this.dtPageCount = Math.ceil(data.length / this.dtDataCountPerPage);

      //refresh the pagination
      $(".pager").css("display", "none");
      $(".fPager").css("display", "none");
      //
      if (this.dtPageCount == 0) {
        $(".pageBtn").css("display", "none");
      }
      if (this.dtPageCount > 0) {
        $(".pageBtn").css("display", "inline");
      }
      if (this.dtPageCount == 1) {
        $("#pageFirst").text("1");
        $("#pageSecond").css("display", "none");
        $("#pageEllipsis").css("display", "none");
        $("#pageLast").css("display", "none");
      }
      if (this.dtPageCount == 2) {
        $("#pageFirst").text("1");
        $("#pageSecond").text("2");
        $("#pageEllipsis").css("display", "none");
        $("#pageLast").css("display", "none");
      }
      if (this.dtPageCount == 3) {
        $("#pageFirst").text("1");
        $("#pageSecond").text("2");
        $("#pageEllipsis").css("display", "none");
        $("#pageLast").text("3");
      }
      if (this.dtPageCount >= 4) {
        $(".fPager").css("display", "inline");
      }
      if (this.dtPageCount >= 4 && this.dtCurPage == 0) {
        $("#pageFirst").text("1");
        $("#pageSecond").text("2");
        $("#pageEllipsis").text("3");
        $("#pageLast").text("4");
      }
      if (this.dtPageCount >= 4 && this.dtCurPage > 0) {
        $("#pageL").css("display", "inline");
      }
      if (this.dtPageCount >= 4 && this.dtCurPage <= this.dtPageCount - 2) {
        $("#pageR").css("display", "inline");
      }
      //
      var cp = "" + (this.dtCurPage + 1);
      let $this = this;
      //
      if (this.dtPageCount >= 4 && this.dtCurPage > 0) {
        if (this.dtCurPage < this.dtPageCount - 2) {
          document.querySelectorAll(".pageBtn").forEach(function (item, index) {
            item.innerText = index + $this.dtCurPage;
          });
        }
        if (this.dtCurPage == this.dtPageCount - 2) {
          document.querySelectorAll(".pageBtn").forEach(function (item, index) {
            item.innerText = index + $this.dtCurPage - 1;
          });
        }
        if (this.dtCurPage == this.dtPageCount - 1) {
          document.querySelectorAll(".pageBtn").forEach(function (item, index) {
            item.innerText = index + $this.dtCurPage - 2;
          });
        }
      }
      //
      //show items by page
      this.dtPageData = this.dtOrder(this.dtFilter(data, this.dtFilterValue)).filter((item, index) => {
        if (
          index >= this.dtCurPage * this.dtDataCountPerPage &&
          index < (this.dtCurPage + 1) * this.dtDataCountPerPage
        ) {
          return true;
        } else return false;
      });
      //
      //select the current element
      let objs = document.querySelectorAll(".pageBtn");
      objs.forEach(function (item) {
        item.classList.replace("btn-primary", "btn-outline-primary");
      });
      cp = this.dtCurPage + 1;
      objs.forEach(function (item) {
        if (item.innerText.trim() == cp)
          item.classList.replace("btn-outline-primary", "btn-primary");
      });
    },

    dtFilter(data, filter) {
      let newData = [];
      //
      data.forEach((item) => {
        //add item to array only if it contains the filtred text
        let txt = "";
        for (var key in item) txt = txt + " " + item[key];
        if (txt.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
          newData.push(item);
        }
      });
      //
      return newData;
    },

    dtOrder(data) {
      let cols = document.querySelectorAll("[dt_column]");
      //
      let col = null;
      let order = null;
      cols.forEach(function (item) {
        if (item.getAttribute("dt_order") != null) {
          col = item.getAttribute("dt_column");
          order = item.getAttribute("dt_order");
        }
      });

      switch (col) {
        case "id":
          data.sort((a, b) => {
            if (a.item_id < b.item_id) {
              return order === "ASC" ? -1 : 1;
            }
            if (a.item_id > b.item_id) {
              return order === "ASC" ? 1 : -1;
            }
            return 0;
          });
          break;
        //
        case "usermail":
          data.sort((a, b) => {
            if (order === "ASC") {
              return a.email.localeCompare(b.email, undefined, {
                sensitivity: "base",
              });
            }
            return b.email.localeCompare(a.email, undefined, {
              sensitivity: "base",
            });
          });
          break;
        //
        case "stockage":
          data.sort((a, b) => {
            if (order === "ASC") {
              return a.quota.localeCompare(b.quota, undefined, {
                sensitivity: "base",
              });
            }
            return b.quota.localeCompare(a.quota, undefined, {
              sensitivity: "base",
            });
          });
          break;
        //
        case "status":
          data.sort((a, b) => {
            if (order === "ASC") {
              return a.status.localeCompare(b.status, undefined, {
                sensitivity: "base",
              });
            }
            return b.status.localeCompare(a.status, undefined, {
              sensitivity: "base",
            });
          });
          break;
        //
        case "quota":
          data.sort((a, b) => {
            if (a.quota - 0 < b.quota - 0) {
              return order === "ASC" ? -1 : 1;
            }
            if (a.quota - 0 > b.quota - 0) {
              return order === "ASC" ? 1 : -1;
            }
            return 0;
          });
          break;
      }
      //
      return data;
    },

    checkInputNumber(e) {
      e.target.value = Math.max(e.target.min, Math.min(e.target.max, e.target.value));
    },

    round(value, step) {
      step || (step = 1.0);
      var inv = 1.0 / step;
      return Math.ceil(value * inv) / inv;
    },

    //to convert bytes to mégabytes
    bToM(bytes) {
      return bytes / (1024 * 1024);
    },

    bToG(bytes) {
      return bytes / (1024 * 1024 * 1024);
    },

    moment(date) {
      return moment(date);
    },

    correctDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    getServerTimeInMs() {
      return functions.syncHttpGet(Globals.website_url + "php/api.php?query=get-current-time-in-ms") - 0;
    },

    currentDate() {
      const current = new Date(this.serverTime);
      return current;
    },

    currentPreDate() {
      //14 days or 2 weeks before exp
      const current = new Date(this.serverTime + 1209600000);
      return current;
    },

    currentAfterDate() {
      //14 days or 2 weeks before exp
      const current = new Date(this.serverTime - 30 * 24 * 60 * 60 * 1000);
      return current;
    },

    openMenu() {
      $('#suspendMenuBtn').attr("email", this.curEmail.email);
      $('#unsuspendMenuBtn').attr("email", this.curEmail.email);
      $('#menuModalBack').css("display", "block");
    },

    closeMenu() {
      $('#menuModalBack').css("display", "none");
    },

    suspendMail(e) {
      let email = e.target.getAttribute('email');
      //
      popup.action = "suspend-mail";
      popup.setTitle("Notification");
      popup.setMessage(
        "<p>Voulez-vous vraiment suspendre ce compte?<br>L'utilisateur ne pourra plus avoir accès à sa boîte mail.</p>"
      );
      popup.setButton("Oui", "Non");
      popup.show();
      //
      //
      $('#popupYes').click(() => {
        if (popup.action != "suspend-mail") return;
        //
        waitLoader.show();
        //to save new data
        axios.get(
          Globals.website_url + "php/emailApi/api.php?query=suspend-email/" + this.curDomainName + "/" + email).then((response) => {
            let h = response.data;
            console.log(h);
            //on error
            if (h <= 0) {
              //
              waitLoader.hide();
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage(
                "Echec de suspension du compte.<br>Veuillez reéssayer ultérieurement."
              );
              popup.setButton("Ok");
              popup.show();
              return;
            }
            else if (h.errors != null) {
              //
              waitLoader.hide();
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage(
                "Echec de suspension du compte.<br>Veuillez reéssayer ultérieurement."
              );
              popup.setButton("Ok");
              popup.show();
              return;
            }
            //
            //on success
            this.forceUpdate();
            //
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Compte suspendu avec succès."
            );
            popup.setButton("Ok");
            popup.show();
            //
          }).catch(() => {
            //
            waitLoader.hide();
          });
      });
    },

    unsuspendMail(e) {
      let email = e.target.getAttribute('email');
      //
      popup.action = "unsuspend-mail";
      popup.setTitle("Notification");
      popup.setMessage(
        "<p>Voulez vous vraiment activé ce compte?<br>L'utilisateur pourra dès activation avoir accès a sa boîte mail.</p>"
      );
      popup.setButton("Oui", "Non");
      popup.show();
      //
      //
      $('#popupYes').click(() => {
        if (popup.action != "unsuspend-mail") return;
        //
        waitLoader.show();
        //to save new data
        axios.get(
          Globals.website_url + "php/emailApi/api.php?query=unsuspend-email/" + this.curDomainName + "/" + email).then((response) => {
            let h = response.data;
            //on error
            if (h <= 0) {
              //
              waitLoader.hide();
              //say error
              popup.action = "Notification";
              popup.setTitle("Notification");
              popup.setMessage(
                "Echec de l'activation du compte.<br>Veuillez reéssayer ultérieurement."
              );
              popup.setButton("Ok");
              popup.show();
              return;
            }
            else if (h.errors != null) {
              //
              waitLoader.hide();
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage(
                "Echec de l'activation du compte.<br>Veuillez reéssayer ultérieurement."
              );
              popup.setButton("Ok");
              popup.show();
              return;
            }
            //
            //on success
            this.forceUpdate();
            //
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Compte activé avec succès."
            );
            popup.setButton("Ok");
            popup.show();
            //
          }).catch(() => {
            //
            waitLoader.hide();
          });
      });
    },




    createMail() {
      //
      if (this.emails.length < this.totalMailCount || this.totalMailCount == -1) {
        $('#messCreateModalBack').css("display", "block");
      }
    },

    callPasswordMail() {
      $('#menuModalBack').css("display", "none");
      //
      let ids = -1;
      this.emails.forEach((email, index) => {
        if (email == this.curEmail) {
          ids = index;
        }
      });
      //
      $("#messPUsernameInput").val(this.curEmail.email);
      $("#messPUsernameInput").attr("ids", ids);
      //
      $('#messPasswordModalBack').css("display", "block");
    },

    passwordMail(e) {
      $("#messPUsernameInput").val(this.emails[e.target.getAttribute("ids")].email);
      $("#messPUsernameInput").attr("ids", e.target.getAttribute("ids"));
      //
      $('#messPasswordModalBack').css("display", "block");
    },


    callManageMail() {
      $('#menuModalBack').css("display", "none");
      //
      let ids = -1;
      this.emails.forEach((email, index) => {
        if (email == this.curEmail) {
          ids = index;
        }
      });
      //
      $("#messMUsernameInput").val(this.curEmail.email);
      $("#messMUsernameInput").attr("ids", ids);
      $("#messMQuotaInput").val(this.bToM(this.curEmail.quota));
      //
      $('#messManageModalBack').css("display", "block");
    },

    manageMail(e) {
      $("#messMUsernameInput").val(this.emails[e.target.getAttribute("ids")].email);
      $("#messMUsernameInput").attr("ids", e.target.getAttribute("ids"));
      $("#messMQuotaInput").val(this.bToM(this.emails[e.target.getAttribute("ids")].quota));
      //
      $('#messManageModalBack').css("display", "block");
    },

    validateCreateMail() {
      let username = $("#messCUsernameInput").val();
      let password = encodeURI($("#messCPasswordInput").val());
      let quota = $("#messCQuotaInput").val();
      //
      let yourEmail = "null";
      if (this.useMailCheck2) {
        yourEmail = $("#messPMailInput2").val();
        if (yourEmail == "") {
          return;
        }
      }
      else {
        if (password == "") {
          return;
        }
      }
      //
      if (password.length == 0) {
        password = encodeURI(functions.generateEmailProPassword());
      }
      //
      waitLoader.show();
      //
      let fd = new FormData();
      fd.append("query", "create-email/" + this.curDomainName + "/" + username + "/" + password + "/" + quota + "/" + yourEmail);
      //
      axios.post(
        Globals.website_url + "php/emailApi/api.php", fd).then((response) => {
          let h = response.data;
          //on error
          if (h == "-3") {
            waitLoader.hide();
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");

            popup.setMessage(
              "Echec de l'opération."
            );
            popup.setButton("Ok");
            popup.show();
            return;
            //
          }
          else if (h == 0) {
            waitLoader.hide();
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Votre mot de passe n'est pas assez sécurisé.<br>Ajoutez y des chiffres, des lettres et des symboles."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          //
          else if (h == -1) {
            waitLoader.hide();
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Veuillez vérifier votre adresse email et reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          //
          else if (h.errors != null) {
            if (h.errors[0].toLowerCase().indexOf("the account") >= 0) {
              waitLoader.hide();
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage(
                "Ce compte existe déjà."
              );
              popup.setButton("Ok");
              popup.show();
              return;
            }
            //
            else if (h.errors[0].toLowerCase().indexOf("password") >= 0) {
              waitLoader.hide();
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage(
                "Votre mot de passe n'est pas assez sécurisé. Ajoutez y des symboles."
              );
              popup.setButton("Ok");
              popup.show();
              return;
            }
          }
          //
          //on success
          this.forceUpdate();
          //
          waitLoader.hide();
          //
          popup.action = "notification";
          popup.setTitle("Notification");
          if (this.useMailCheck2) {
            popup.setMessage(
              "<p>Compte créé avec succès.<br>Le mail de réinitialisation du mot de passe a été envoyé avec succès.</p>"
            );
          }
          else {
            popup.setMessage(
              "Compte créé avec succès."
            );
          }
          popup.setButton("Ok");
          popup.show();
          //
        }).catch(() => {
          //
          waitLoader.hide();
        });
    },

    validatePasswordMail() {
      let email = this.emails[$("#messPUsernameInput").attr("ids")].email;
      let password = encodeURI($("#messPPasswordInput").val());
      let yourEmail = "null";

      if (this.useMailCheck) {
        password = encodeURI(functions.generateEmailProPassword());
        //
        yourEmail = $("#messPMailInput").val();
        if (yourEmail == "") {
          return;
        }
      }
      else {
        if (password == "") {
          return;
        }
      }
      //
      if (password.length == 0) {
        password = encodeURI(functions.generateEmailProPassword());
      }
      //
      waitLoader.show();
      //
      let fd = new FormData();
      fd.append("query", "email-set-password/" + this.curDomainName + "/" + email + "/" + password + "/" + yourEmail);
      //
      axios.post(
        Globals.website_url + "php/emailApi/api.php", fd).then((response) => {
          let h = response.data;
          //on error
          if (h == "-3") {
            waitLoader.hide();
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Echec de l'opération."
            );
            popup.setButton("Ok");
            popup.show();
            return;
            //
          }
          else if (h == 0) {
            waitLoader.hide();
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "<p>Votre mot de passe n'est pas assez sécurisé.<br> Ajoutez y des chiffres, des lettres et des symboles.<p>"
            );
            popup.setButton("Ok");
            popup.show();
            return;
            //
          }
          else if (h == "-1") {
            //
            waitLoader.hide();
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Veuillez vérifier votre adresse email et reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          //
          waitLoader.hide();
          //
          popup.action = "notification";
          popup.setTitle("Notification");
          if (this.useMailCheck) {
            popup.setMessage(
              "<p>Le mail de réinitialisation du mot de passe a été envoyé avec succès.</p>"
            );
          }
          else {
            popup.setMessage(
              "Mot de passe modifié avec succès."
            );
          }
          popup.setButton("Ok");
          popup.show();
          //
          this.forceUpdate();
          //
        }).catch(() => {
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage(
            "Veuillez vérifier votre connexion à internet."
          );
          popup.setButton("Ok");
          popup.show();
          //
          waitLoader.hide();
        });
    },

    validateManageMail() {
      let email = this.emails[$("#messMUsernameInput").attr("ids")].email;
      let quota = $("#messMQuotaInput").val();
      //
      waitLoader.show();
      //
      axios.get(
        encodeURI(Globals.website_url + "php/emailApi/api.php?query=email-set-quota/" + this.curDomainName + "/" + email + "/" + quota)).then((response) => {
          let h = response.data;
          //on error
          if (h <= 0) {
            //
            waitLoader.hide();
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Cet compte email n'existe pas."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          //
          this.forceUpdate();
          //
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage(
            "Espace de stockage modifié avec succès."
          );
          popup.setButton("Ok");
          //
        }).catch(() => {
          //
          waitLoader.hide();
        });
    },

    callDeleteMail() {
      $('#menuModalBack').css("display", "none");
      //
      let ids = -1;
      this.emails.forEach((email, index) => {
        if (email == this.curEmail) {
          ids = index;
        }
      });
      //
      $("#messMUsernameInput").attr("ids", ids);
      this.validateDeleteMail();
    },

    validateDeleteMail() {
      popup.action = "delete-mail";
      popup.setTitle("Notification");
      popup.setMessage(
        "Voulez-vous supprimer ce compte definitivement?"
      );
      popup.setButton("Oui", "Non");
      popup.show();
      //
      //
      $('#popupYes').click(() => {
        if (popup.action != "delete-mail") return;
        waitLoader.show();
        //
        let email = this.emails[$("#messMUsernameInput").attr("ids")].email;
        //
        axios.get(
          Globals.website_url + "php/emailApi/api.php?query=delete-email/" + this.curDomainName + "/" + email).then((response) => {
            let h = response.data;
            //on error
            if (h <= 0) {
              //
              waitLoader.hide();
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage(
                "Echec de supression du compte.<br>Veuillez reéssayer ultérieurement."
              );
              popup.setButton("Ok");
              popup.show();
              return;
            }
            //
            //on success
            this.forceUpdate();
            //
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Compte supprimer avec succès."
            );
            popup.setButton("Ok");
            //
          }).catch(() => {
            //
            waitLoader.hide();
          });
      });
    },

    upgradeOffer() {
      popup.action = "notification";
      popup.setTitle("Notification");
      popup.setMessage(
        "<p>Veuillez contacter l'administrateur d'Ecoweb pour augmenter le quota de compte mail lié à ce nom de domaine.</p>"
      );
      popup.setButton("Ok");
      popup.show();
    }
  }
};
</script>

<style scoped>
@import "/src/common/css/myDatatable.css";

#mess {
  background-color: #f8f8f8;
  padding: 10px 16px;
  overflow: auto;
}

#allDomainFilter {
  width: max-content;
  margin-top: 8px;
  display: block;
  justify-content: center;
}

#allDomainFilter a {
  font-size: 23px;
  margin-right: 4px;
}

#allDomainFilter select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 5px;
  background-color: white;
  padding: 4px 16px;
  padding-right: 32px;
  /**/
  border: solid 1px #ddd;
  border-radius: 4px;
  margin-right: 16px;
}


#subsUpdateBtn {
  position: relative;
  top: -3px;
}


#messHeader {
  height: 80px !important;
}

#messMessage {
  font-size: 22px;
}

#messMailCount {
  width: max-content;
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 12px;
  background-color: #eee;
}


#messSearch {
  display: block !important;
  width: 100% !important;
  height: 38px !important;
}

#messSearchBox {
  width: 248px;
  margin-right: 8px;
  margin-bottom: 24px;
}








.field {
  width: max-content;
  height: 38px !important;
}

.field.active {
  border-color: #7d2ae8;
}

.field span {
  /* font-size: 28px; */
}

.field.active span {
  color: #7d2ae8;
}

.field button {
  padding: 5px 16px;
  font-weight: 500;
  font-size: 13px;
}

.field input {
  flex: 1;
  font-size: 13px;
}






/*  */
/*  */
/*  */


.modal {
  background-color: rgba(0, 0, 0, 0.35);
}

.modal-dialog {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateX(-50%) translateY(-50%);
  width: max-content;
  max-width: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 4px;
  z-index: 1000 !important;
}

.modal-body {
  width: 512px;
  max-width: 100%;
}

.menuBody {
  margin-left: -16px;
  width: 260px;
}

.menuBody div {
  cursor: pointer;
}

.menuBody div:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}







/*  */
/*  */
/*  */
#messHomeBoard {
  margin-top: 48px;
  border: solid 1px #ccc;
  overflow-x: auto;
}

#messHomeBoard>div {
  min-width: 700px;
}

table {
  min-width: 700px;
  margin: 16px 0 16px 0;
  border: solid 1px #ccc;
  overflow: hidden;
  border-left: none !important;
  border-right: none !important;
}

thead {
  border-color: #ccc !important;
  cursor: pointer;
  background-color: #f8f8f8;
}

th {
  padding: 16px 0 !important;
  text-transform: uppercase;
  text-align: left;
  border-bottom-color: #ddd !important;
}

.messDataTableSortIcon {
  visibility: hidden;
  color: #f80;
}

thead th:hover .messDataTableSortIcon {
  visibility: visible;
}

td {
  vertical-align: middle !important;
}

td>a {
  padding: 3px 12px;
  background-color: #6fa;
  border-radius: 16px;
  font-weight: bold;
}

td strong {
  padding: 3px 12px;
  border-radius: 16px;
  font-weight: bold;
}

td img {
  width: 40px;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 6px;
}

td span {
  width: 64px;
}

td div a,
td div b {
  text-align: left;
}

td div a {
  color: #999;
}

td div a:hover {
  color: #999;
}

td div {
  width: 100% !important;
  height: max-content !important;
  display: flex;
  justify-content: center;
}

td button {
  width: max-content !important;
  margin: 0 4px;
  padding: 3px 20px !important;
  font-weight: bold;
}


.bar {
  display: block;
  width: 100%;
  border: solid 1px #555;
  height: 8px !important;
  margin-top: 5px;
}

.bar .barValue {
  display: block;
  background-color: deepskyblue;
  height: 100% !important;
  width: 0;
}

@media only screen and (max-width:1485px) {
  .table-btn {
    margin-bottom: 8px;
  }
}










#noMail {
  position: relative;
  top: 48px;
  width: 280px !important;
  max-width: 100%;
}





.state {
  padding: 3px 12px;
  border-radius: 16px;
  font-weight: bold;
  width: max-content !important;
}





.importantSentence {
  font-size: 13px;
  color: #b55 !important;
}

.useMailChecker {
  position: static !important;
}







.manageServicesItem {
  padding: 0 !important;
  width: 100%;
  background-color: #f2fbff;
  border: solid 1px #ccc;
  height: max-content;
  color: #111a2b;
  margin: 24px 0;
  text-align: center;
}

.manageServicesTitle {
  display: block;
  background-color: white;
  width: 100%;
  padding: 8px 0;
  border-bottom: solid 1px #ccc;
}

.manageServicesSubitem {
  text-align: center;
  padding: 0 8px 16px 8px;
}


.manageServicesItem a {
  color: black;
}

.manageServicesItem b {}

.manageServicesItem button {
  margin-bottom: 8px;
  border-radius: 16px;
  padding: 8px 0;
  color: white;
  border: none;
  width: 112px;
}










@media only screen and (max-width:670px) {
  #mess {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  #allDomainFilter {
    width: 100% !important;
    display: block !important;
    text-align: center !important;
  }

  #allDomainFilter br {
    display: block !important;
  }

  #subsUpdateBtn {
    width: 100% !important;
  }

  #messHeader {
    text-align: center !important;
  }

  #messMessage {
    display: block;
    font-size: 15px;
    float: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #messMessage br {
    display: block !important;
  }

  #messMailCount {
    display: inline-block;
    font-size: 15px;
    float: none !important;
    height: 48px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #messSearchBox {
    width: 100%;
    margin-right: 0;
  }

  .field {
    width: 100% !important;
  }

  .field button {
    display: none;
  }

  #messMenuBtn {
    display: block !important;
    width: 100%;
    float: left !important;
  }

  .table-btn {
    width: 100% !important;
  }

  #messMenuBtn button {
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 I !important;
  }




  .modal-dialog {
    margin: 0 !important;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>