<template>
    <div id="userChoosePhoto">
        <h3>Photo de profil</h3>

        <div id="defaultUserImage">
            <img :src="(photoPath == null || photoPath == '') ? './user-default-image.webp' : server_url + 'media/images/' + photoPath"
                onclick="document.getElementById('importPhotoFile').click()">
            <i class="bi bi-camera-fill" onclick="document.getElementById('importPhotoFile').click()"></i>
        </div>

        <div id="croppieContainer">
            <img id="croppieView" />
        </div>

        <input type="file" id="importPhotoFile" @change="importPhoto" accept=".jpeg,.jpg,.png,.bmp">

        <button id="validateBtn" @click="uploadFile">Valider</button>
        <button onclick="document.getElementById('importPhotoFile').click()">Choisir une Photo</button>
        <button @click="back">Annuler</button>
    </div>
</template>

<script>
const $ = require("jquery");
import waitLoader from "/src/common/js/waitLoader";
import popup from "/src/common/js/popup";
import functions from "/src/common/js/functions";
import axios from "axios";
import Globals from "/src/common/js/Globals";
import localforage from "localforage";
//
import Cropper from 'cropperjs';
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";



//userChangePhoto component
export default {
    computed: {
        ...mapStores(useUsersStore),
    },

    data() {
        return {
            croppieView: null,
            photoPath: null,
            server_url: Globals.website_url,
        }
    },

    mounted() {
        this.__update();
    },

    methods: {
        __update() {
            waitLoader.show();
            this.update();
            //
            setTimeout(() => {
                waitLoader.hide();
            }, 1000);
        },

        back: function () {
            this.$router.push("/client/Home");
        },

        async update() {
            let jsData;
            try {
                jsData = await localforage.getItem('ecocloud/User');
            } catch (err) {
                // This code runs if there were any errors.
                this.$router.replace("/login");
            }
            jsData = JSON.parse(jsData);
            this.usersStore.User = jsData;
            //
            if (this.usersStore.User == null) {
                this.$router.replace("/login");
            }
            //
            if (this.usersStore.User.data == null) {
                this.$router.replace("/login");
            }
            //
            let id = functions.correctQueryString(this.usersStore.User.account.id);
            let h = functions.syncHttpGet(Globals.website_url + "php/api.php?query=get-user-data-by-id/" + id);
            this.usersStore.User.data = JSON.parse(h);
            //
            this.photoPath = this.usersStore.User.data.photo_path;
            //
            //
            this.croppieView = new Cropper($("#croppieView")[0], {
                aspectRatio: 10 / 10,
                viewMode: 2,
                ready() {
                    $(".cropper-crop-box,.cropper-view-box,.cropper-face").css("border-radius", "50%");
                }
            });
        },


        importPhoto: function (e) {
            //import photo from input file and bind in croppie view
            waitLoader.show();
            //
            this.croppieView.replace(URL.createObjectURL(e.target.files[0]));
            //hidding and displaying
            $("#defaultUserImage").css("display", "none");
            $("#croppieContainer").css("display", "block");
            $("#validateBtn").css("display", "block");
            //
            setTimeout(() => {
                waitLoader.hide();
            }, 1000);
        },

        uploadFile() {
            waitLoader.show();
            //
            this.croppieView.getCroppedCanvas().toBlob((blob) => {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    var base64data = reader.result;
                    this.cropAndUploadImage(base64data);
                }
            });
        },

        cropAndUploadImage(result) {
            let formData = new FormData();
            formData.append("image", result);
            //
            axios.post(Globals.website_url + "php/uploadCroppedImage.php", formData).then((response) => {
                let h = response.data;
                //on error
                if (h == "") {
                    //
                    waitLoader.hide();
                    //say error
                    popup.action = "notification";
                    popup.setTitle("Notification");
                    popup.setMessage("problème de téléchargement de la photo !<br>Veuillez reéssayer.");
                    popup.setButton("Ok");
                    popup.show();
                    return;
                }
                //on success
                h = functions.correctQueryString(h);
                let photoPath = h;
                let uid = functions.correctQueryString(this.usersStore.User.account.id);
                //assign the photo url to the user in teh database
                axios.post(Globals.website_url + "php/api.php?query=choose-first-user-photo/" + uid + "/" + photoPath).then((response) => {
                    let h = response.data;
                    //on error
                    if (h != "1") {
                        //say error
                        popup.action = "notification";
                        popup.setTitle("Notification");
                        popup.setMessage("problème de liaison de la photo à l'utilisateur!<br>Veuillez reéssayer.");
                        popup.setButton("Ok");
                        popup.show();
                        //
                        waitLoader.hide();
                        //
                        return;
                    }
                    //
                    waitLoader.hide();
                    //on success
                    //connect redirect to home
                    this.$router.push("/client/Home");
                    //
                }).catch(() => {
                    //say error
                    popup.action = "notification";
                    popup.setTitle("Notification");
                    popup.setMessage("Veuilez vérifier votre connexion à internet.");
                    popup.setButton("Ok");
                    popup.show();
                    //
                    waitLoader.hide();
                });
            }).catch(() => {
                //say error
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage("Impossible de télécharger votre photo.");
                popup.setButton("Ok");
                popup.show();
                //
                waitLoader.hide();
            });
        },

    }
};
</script>

<style scoped>
@import "cropperjs/dist/cropper.css";

body {
    background-color: #ccc !important;
}

#userChoosePhoto {
    color: black;
    max-width: 480px;
    width: 100%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    float: left;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    padding: 24px 0 0 0;
}

#userChoosePhoto h3 {
    margin-bottom: 24px;
}

input[type="file"] {
    display: none;
}

#defaultUserImage {
    width: 100%;
    height: 300px;
    padding: 50px 0;
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    cursor: pointer;
}

#defaultUserImage:hover i {
    display: inline;
}

#defaultUserImage img {
    width: 200px;
    height: 200px;
    padding: 1px;
    border-radius: 50%;
}

#defaultUserImage i {
    position: absolute;
    left: calc(50% - 37px);
    top: calc(50% - 16px);
    font-size: 48px;
    background-color: #eee;
    padding: 0 12px;
    border-radius: 50%;
    border: solid 1px white;
}



#croppieContainer {
    display: none;
    border: solid 1px #ccc;
    border-left: none;
    border-right: none;
    height: 300px !important;
}

#croppieView {
    display: block;
    max-width: 100%;
    max-height: 100%;
}






button {
    margin: 0;
    padding: 8px 0;
    text-align: center;
    font-size: 16px;
    width: 50%;
    float: left;
    border: none;
    background-color: transparent;
}

button:last-child {
    border-left: solid 1px #ccc;
}

button:hover {
    background-color: #eee;
    color: black;
}

#validateBtn {
    margin-top: 50px;
    display: none;
    width: 100%;
    padding: 8px 0;
    border-bottom: solid 1px #ccc;
    border-top: solid 1px #ccc;
}

#validateBtn:hover {
    background-color: #6bf;
}

/**/
@media only screen and (max-width: 480px) {
    #userChoosePhoto {
        box-shadow: none;
        border: solid 1px #ccc;
        border-left: none;
        border-right: none;
        border-radius: 0;
    }
}

@media only screen and (max-width: 320px) {
    .cr-boundary {
        width: 240px !important;
        height: 240px !important;
    }

    .cr-viewport {
        width: 160px !important;
        height: 160px !important;
    }
}

@media only screen and (max-height: 512px) {
    #userChoosePhoto {
        top: 0;
        transform: translateY(0) translateX(-50%);
    }
}

@media only screen and (max-width: 280px) {
    #defaultUserImage img {
        width: 160px;
        height: 160px;
        margin-top: 20px;
    }
}
</style>