import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
//
import VueGtag from "vue-gtag";
//
//
//imported css
import 'intl-tel-input/build/css/intlTelInput.css';
//
//
import { createPinia, setActivePinia } from "pinia";
const pinia = createPinia();
setActivePinia(pinia);
//
const app = createApp(App);
//
app.use(router);
app.use(pinia);
app.use(VueGtag, {
    config: {
        id: "G-HJ0RJJZZFD",
        send_page_view: true
    }
}, router);
//
app.mount('#app');
//
//
//
//

// import './registerServiceWorker'
// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/service-worker.js').then((registration) => {
//         registration.onupdatefound = () => {
//             const installingWorker = registration.installing;
//             installingWorker.onstatechange = () => {
//                 if (installingWorker.state === 'installed') {
//                     if (navigator.serviceWorker.controller) {
//                         // New content is available
//                         // Notify users to refresh the page

//                     } else {
//                         // This is the initial service worker installation

//                     }
//                 }
//             };
//         };
//     }).catch((error) => {
//         alert("Erreur rencontrée lors de l'installation de l'application : " + error);
//     });
// }