const $ = require("jquery");

export default {
  setLabel(text) {
    $("#waitLoaderLabel")[0].innerHTML = text;
    let show = document.createAttribute("show");
    $("#waitLoaderLabel")[0].setAttributeNode(show);
  },

  show() {
    //
    if ($("#preloader") != null) $("#preloader").remove();
    //
    let show = document.createAttribute("show");
    $("#waitLoader")[0].setAttributeNode(show);
    $("body")[0].style.overflow = "hidden";
  },

  hide() {
    $("#waitLoaderLabel")[0].removeAttribute("show");
    $("#waitLoader")[0].removeAttribute("show");
    $("body")[0].style.overflowY = "auto";
    $('input').focusout();
  },
};