<template>
    <main>
        <form id="emailConfirmed" @submit.prevent="validate()" class="text-center">
            <img src="@/assets/images/logo-ecodev.png">
            <!--  -->
            <h4>Félicitation, votre email a bien été confirmé!</h4>

            <i class="bi bi-check-circle"></i>

            <div class="w-100 d-flex justify-content-center">
                <button type="submit">
                    Se connecter
                    <i class="bi bi-check-lg"></i>
                </button>
            </div>
        </form>
    </main>
</template>


<style scoped>
form {
    color: black;
    max-width: 680px;
    width: 100%;
    background-color: #F2FBFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    float: left;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
    padding: 112px;
}

form img {
    width: 260px;
    margin-bottom: 32px;
}


form h3 {
    margin-bottom: 24px;
}

form input {
    border: solid 1px #ddd;
    padding: 12px 12px;
}

form i {
    font-size: 128px;
    color: rgb(130, 211, 54);
}

form button[type=submit] {
    margin-left: 12px;
    margin-top: 32px;
    border: none;
    border-radius: 3px;
    padding: 8px 0;
    width: 240px;
    box-sizing: content-box;
    color: white;
    background-color: #111a2b;
    font-size: 17px;
}

form button[type=submit]:hover {
    color: white;
    background-color: #1b2842;
}

form button[type=submit] i {
    color: rgb(123, 255, 0);
    font-size: 16px;
}

form button[type=submit]:hover {
    background-color: #346;
}

/**/
@media only screen and (max-width: 480px) {
    form {
        box-shadow: none;
        border: solid 1px #ccc;
        border-left: none;
        border-right: none;
        border-radius: 0;
    }
}

@media only screen and (max-height: 512px) {
    form {
        top: 0;
        transform: translateY(0) translateX(-50%);
    }
}
</style>


<script>
import functions from "/src/common/js/functions";
import Globals from "/src/common/js/Globals";

export default {
    data() {
        return {

        }
    },

    created() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let aid = urlParams.get('aid');
        let ecode = urlParams.get('ecode');

        let h = functions.syncHttpGet(Globals.website_url + "php/emailConfirmed.php?aid=" + aid + "&ecode=" + ecode);

        if (h != "1") {
            location = "/404.html";
            return;
        }

        localforage.setItem('ecocloud/common', JSON.stringify({ cutdown: 1, counter: 0 })).then(() => {
        }).catch(function (err) {
            console.log(err);
        });
    },

    methods: {
        validate() {
            this.$router.replace("/login");
            return false;
        }
    }
}
</script>