<template>
    <select class="form-control" required>
        <option value="">Aucun Pays sélectionné</option>
        <option v-for="c, index in cc" :key="index" :value="c.code">{{ c.name }}</option>
    </select>
</template>


<script>
import countryList from "./countryList.json";

export default {
    data() {
        return {
            cc: countryList,
        };
    },

    mounted() {
    }
}
</script>

<style></style>