import { createRouter, createWebHashHistory } from 'vue-router'
//
import routes_sign from './routes/routes_sign';
import routes_client from './routes/routes_client';
import routes_security from "./routes/routes_security";
import routes_external from "./routes/routes_external";

const routes = [
    ...routes_client,
    ...routes_sign,
    ...routes_security,
    ...routes_external
];

var router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
});

export default router;