<template>
  <main id="home">
    <h1 id="welcomeMess">Bienvenue sur Ecoweb</h1>

    <div id="helpMess" v-show="hasEnterprise">
      <img src="@/assets/images/hint.png">
      <a>
        Dans votre espace client Ecoweb, vous pourrez gérer vos prestations, suivre vos commandes et consulter vos
        factures avec une interface assez intuitive.
      </a>
    </div>

    <section class="d-flex w-100 justify-content-center">
      <div id="homeBoard" class="row justify-content-center">
        <div class="col-12 col-sm-6" v-show="hasEnterprise && isDataCompleted">

          <button class="homeBoardItem hvr-float-shadow text-dark border-0" @click="downloadApp()"
            v-if="deferredPrompt">
            <div class="homeBoardItemPart1">
              <i class="bi bi-download"></i>
            </div>

            <div class="homeBoardItemPart2">
              <h4>Ecoweb Application</h4>
              <p>
                Installer notre application <strong>Ecoweb</strong> pour un accès sans navigateur internet dès
                maintenant.
              </p>
            </div>
          </button>

          <router-link class="homeBoardItem hvr-float-shadow text-dark" to="/client/Offers">
            <div class="homeBoardItemPart1">
              <i class="bi bi-cart4"></i>
            </div>

            <div class="homeBoardItemPart2">
              <h4>Commander un Service</h4>
              <p>
                Avec <strong>Ecoweb</strong> vous avez la possibilité de souscrire à des Offres Uniques avec toutes
                leurs fonctionnalités.
              </p>
            </div>
          </router-link>

          <router-link class="homeBoardItem hvr-float-shadow text-dark" to="/client/Subscriptions/Services">
            <div class="homeBoardItemPart1">
              <i class="bi bi-stack"></i>
            </div>

            <div class="homeBoardItemPart2">
              <h4>Mes Services & Factures</h4>
              <p>
                Gérer tous vos services actifs ou expirés,
                leurs rénouvellements, ou aussi téléchargez vos factures.
              </p>
            </div>
          </router-link>
        </div>





        <div class="col-12 col-sm-6">
          <router-link class="homeBoardItem hvr-float-shadow text-dark" to="/client/UserData/EntrepriseData"
            v-show="!hasEnterprise || !isDataCompleted">
            <div class="homeBoardItemPart1">
              <i class="bi bi-person-lines-fill"></i>
            </div>

            <div class="homeBoardItemPart2">
              <h4>Finaliser votre inscription</h4>
              <p>
                Vous devez remplir vos informations personnelles et celles de votre entreprise pour avoir accès à nos
                services.
              </p>
            </div>
          </router-link>

          <router-link class="homeBoardItem hvr-float-shadow text-dark" to="/client/email-panel"
            v-show="hasEnterprise && isDataCompleted && hasOneDomainWithMail">
            <div class="homeBoardItemPart1">
              <i class="bi bi-envelope-fill"></i>
            </div>

            <div class="homeBoardItemPart2">
              <h4>Messagerie Professionnelle</h4>
              <p>
                Accéder à vos nom de domaines et
                Gérer vos adresses mails professionnels en seulement quelques clics.
              </p>
            </div>
          </router-link>

          <router-link class="homeBoardItem hvr-float-shadow text-dark" to="/client/UserData/Infos">
            <div class="homeBoardItemPart1">
              <i class="bi bi-person-lines-fill"></i>
            </div>

            <div class="homeBoardItemPart2">
              <h4>Informations Personnelles</h4>
              <p>
                Ici vous pouvez consulter votre fiche client, ou modifier vos données d'utilisateur.
              </p>
            </div>
          </router-link>


          <router-link class="homeBoardItem hvr-float-shadow text-dark" to="/client/Supports/menu">
            <div class="homeBoardItemPart1">
              <i class="bi bi-question-diamond-fill"></i>
            </div>

            <div class="homeBoardItemPart2">
              <h4>Besoin d'Aide ?</h4>
              <p>
                Avec <strong>Ecoweb</strong>, vous saurez toujours quoi faire et vous irez toujours là où vous devez
                aller.
              </p>
            </div>
          </router-link>
        </div>

      </div>
    </section>
  </main>
</template>

<script>
const $ = require("jquery");
import axios from "axios";
//
import Globals from "@/common/js/Globals";
import waitLoader from "@/common/js/waitLoader";
import functions from "@/common/js/functions";
//
import { mapStores } from 'pinia';
import { useUsersStore } from "@/common/stores/users.js";


//login component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      pageName: "Acceuil",
      hasEnterprise: false,
      isDataCompleted: false,
      hasOneDomainWithMail: false,
      deferredPrompt: null,
    };
  },

  mounted() {
    Globals.clientHome = this;
    //PWA
    this.deferredPrompt = Globals.deferredPrompt;
    //
    window.addEventListener('beforeinstallprompt', (e) => {
      Globals.deferredPrompt = e;
      this.deferredPrompt = e;
    });
    //for installation counting
    window.addEventListener("appinstalled", async () => {
      this.deferredPrompt = null;
      await axios.post(Globals.website_url + "php/api.php?query=append-pwa-installation/" + functions.getDeviceType() + "/" + functions.getDevice() + "/" + functions.getOS());
    });
    //
    $("#routeName")[0].innerHTML = this.pageName;
    //
    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
        waitLoader.hide();
      }, 1);
    },

    update() {
      //check if enterprise id exist
      let h = this.usersStore.User.data.enterprise_id;
      this.hasEnterprise = h == null ? false : true;
      this.isDataCompleted = this.usersStore.User.data.creating_step == 1 ? true : false;
      //
      //check if user has one Domain with email
      const checkIfUserHasOneEmailDomain = async () => {
        try {
          const resp = await axios.get(Globals.website_url + "php/api.php?query=check-if-user-has-one-email-domain/" + this.usersStore.User.account.id);
          this.hasOneDomainWithMail = resp.data;
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      };
      checkIfUserHasOneEmailDomain();
    },

    async downloadApp() {
      if (this.deferredPrompt !== null) {
        this.deferredPrompt.prompt();
        const { outcome } = await this.deferredPrompt.userChoice;
        if (outcome === 'accepted') {
          Globals.deferredPrompt = null;
        }
      }
    }
  }
};
</script>

<style scoped>
#home {
  color: black;
  width: 100%;
  position: relative;
  padding-top: 24px;
}

#sloganMess {
  font-family: 'Courier New', Courier, monospace;
  text-transform: uppercase;
  font-size: 32px;
}

#sloganMess a:nth-child(1) {
  animation: messAnim ease 1s;
  animation-delay: 2s;
}

#sloganMess a:nth-child(2) {
  animation: messAnim ease 1s;
  animation-delay: 2.5s;
}

#sloganMess a:nth-child(3) {
  animation: messAnim ease 1s;
  animation-delay: 4.2s;
}

#sloganMess a:nth-child(4) {
  animation: messAnim ease 1s;
  animation-delay: 4.4s;
}

#sloganMess a:nth-child(5) {
  animation: messAnim ease 1s;
  animation-delay: 4.6s;
}

#sloganMess a:nth-child(6) {
  animation: messAnim ease 1s;
  animation-delay: 4.8s;
}

#sloganMess a:nth-child(7) {
  animation: messAnim ease 1s;
  animation-delay: 5s;
}

@keyframes messAnim {
  from {
    font-weight: bolder;
    color: #ff6600;
  }

  to {
    font-weight: normal;
    color: #000;
  }
}








#welcomeMess {}

#helpMess {
  margin-top: 24px;
}

#helpMess img {
  width: 24px;
  position: relative;
  top: -2px;
}

#helpMess a {
  font-size: 15px;
}

/**/
#homeBoard {
  margin: auto;
  margin-top: 24px;
  width: 100% !important;
  max-width: 900px;
  border: none;
}

.homeBoardItem {
  cursor: pointer;
  background-color: #F2FBFF;
  padding: 16px 16px 0px 16px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  float: left;
  margin-bottom: 16px;
}

.homeBoardItem:hover {
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.3);
}

.homeBoardItemPart1 {
  width: 96px;
  float: left;
}

.homeBoardItemPart1 i {
  font-size: 64px;
  background: -webkit-linear-gradient(red, orange);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: orange;
}

.homeBoardItemPart2 {
  width: calc(100% - 96px);
  float: right;
  text-align: left;
}

.homeBoardItemPart2 p {
  color: #000;
}

.homeBoardItemPart2 p strong {
  color: #6af;
}

.homeBoardItemPart3 {
  width: 100%;
  float: left;
  text-align: center;
  background-color: #111a2b;
  color: white;
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 7.5px 12px;
}

.homeBoardItemPart3 p {}

.homeBoardItemLine1 {
  width: 100%;
  float: left;
  text-align: center;
  color: black;
  margin-bottom: 16px;
}

.homeBoardItemLine1:hover {
  color: orange;
}

.homeBoardItemLine1 i {
  color: orange;
  position: relative;
  top: 1px;
  margin-right: 2px;
  font-size: 16px;
  font-weight: bold;
}

.homeBoardItemLine1 a {
  font-size: 14px;
  font-weight: 550;
}

/**/
@media only screen and (max-width: 768px) {
  .homeBoardItemPart1 {
    width: 100%;
  }

  .homeBoardItemPart2 {
    float: left;
    width: 100%;
    text-align: center;
  }
}








.hvr-float-shadow:hover {
  animation: float-shadow 0.6s ease;
}

@keyframes float-shadow {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-14px);
  }

  40% {
    transform: translateY(14px);
  }

  60% {
    transform: translateY(-7px);
  }

  80% {
    transform: translateY(7px);
  }

  100% {
    transform: translateY(0);
  }
}
</style>