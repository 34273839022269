<template>
  <div id="userDataEdit">
    <div id="udTitle" class="d-flex align-items-center">
      <router-link to="/client/UserData/Infos">
        <i id="backButton" class="bi bi-arrow-left-circle-fill me-3"></i>
      </router-link>
      <h4>Modifier mes informations</h4>
    </div>

    <br />

    <form class="row" v-on:submit.prevent="validate">
      <div class="col-sm-6 col-12">
        <div class="form-floating mb-3">
          <input type="text" id="userDataFname" spellcheck="false" class="form-control" placeholder="Krepin Jhon"
            :value="userData.fname" v-on:input="resetInput">
          <label for="userDataFname">Nom complet</label>
        </div>

        <div class="form-floating mb-3">
          <input type="email" id="userDataEmail" class="form-control" placeholder="krepin@jhon.com"
            :value="userData.email" readonly>
          <label for="userDataEmail">Email</label>
        </div>

        <div class="form-floating mb-3">
          <a style="font-size:14px">Telephone</a>
          <br />
          <input type="tel" id="userDataTel" class="form-control" @input="resetInput">
        </div>
      </div>

      <div class="col-sm-6 col-12">
        <div class="form-floating mb-3">
          <CountryList id="userDataEditCountry"></CountryList>
          <label for="userDataEditCountry">Pays</label>
        </div>

        <div class="form-floating mb-3">
          <input type="text" id="userDataCity" class="form-control" placeholder="Ouagadougou" :value="userData.city"
            v-on:input="resetInput">
          <label for="userDataCity">Ville</label>
        </div>

        <div class="form-floating">
          <input type="text" id="userDataAddress" class="form-control" placeholder="Ouaga 2000"
            :value="userData.home_address" v-on:input="resetInput">
          <label for="userDataAddress">Adresse</label>
        </div>
        <div class="d-block mb-3 text-secondary">
          <i class="bi bi-info-circle-fill me-1"></i>
          <a>Exemple: ouaga 2000 azimmo</a>
        </div>
      </div>

      <div class="col-12 d-flex justify-content-center">
        <button type="submit">
          ENREGISTRER LES MODIFICATIONS
          <i class="bi bi-check-lg"></i>
        </button>
      </div>
    </form>

    <PhoneNumberConfirm></PhoneNumberConfirm>
  </div>
</template>

<script>
const $ = require("jquery");
import countryList from "/src/components/countryList.vue";
import waitLoader from "/src/common/js/waitLoader";
import functions from "/src/common/js/functions";
import Globals from "/src/common/js/Globals";
import popup from "/src/common/js/popup";
import axios from "axios";
import phoneNumberConfirm from "/src/components/phoneNumberConfirm.vue";
import intlTelInput from 'intl-tel-input';
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";


//UserDataEdit component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  components: {
    CountryList: countryList,
    PhoneNumberConfirm: phoneNumberConfirm,
  },

  data: function () {
    return {
      pageName: "Modifier mes Informations",
      userData: {
        id: "",
        fname: "",
        email: "",
        tel: "",
        home_address: "",
        country: "",
        city: '',
      },
      intTel: null,
    };
  },

  mounted() {
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    this.intTel = intlTelInput($("#userDataTel")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });
    //
    waitLoader.show();
    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
      }, 1);
    },

    async getUserData() {
      try {
        //get All user data in json format
        let id = this.usersStore.User.account.id;
        id = functions.correctQueryString(id);
        const resp = await axios.get(Globals.website_url + "php/api.php?query=get-user-data-by-id/" + id);
        this.userData = resp.data;
        //refresh the header userName
        $("#headerUserName")[0].innerHTML = this.userData.fname;
        //set user country
        $("#userDataEditCountry")[0].value = this.userData.country;
        this.usersStore.User.data = this.userData;
        //
        this.intTel.setNumber(this.userData.tel);
        //
        waitLoader.hide();
      } catch (err) {
        // Handle Error Here
        waitLoader.hide();
        //
        //say error
        popup.action = "Notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Erreur de récupération des données. Veuillez actualiser la page"
        );
        popup.setButton("Ok");
        popup.show();
        //
        this.userData = {};
      }
    },

    update() {
      this.getUserData();
      //
      //when player want to save new profil data
      $("#popupYes").click(() => {
        //in different popup action
        if (popup.action != "user-set-data") return;
        //
        waitLoader.show();
        //for this popup action
        popup.action = "";
        let id = this.usersStore.User.account.id;
        //
        let tel = this.intTel.getNumber();
        //
        let fname = $("#userDataFname")[0].value;
        //
        let country = $("#userDataEditCountry")[0].value;
        //
        let city = $("#userDataCity")[0].value;
        //
        let address = $("#userDataAddress")[0].value;
        //
        id = functions.correctQueryString(id);
        //tel = functions.correctPhoneNumber(tel);
        fname = functions.correctQueryString(fname);
        country = functions.correctQueryString(country);
        city = functions.correctQueryString(city);
        address = functions.correctQueryString(address);
        //
        //if inputed tel is not the same as in the database
        if (tel == this.usersStore.User.data.tel) {
          //to save new data
          axios.post(
            Globals.website_url + "php/api.php?query=set-user-data-by-id/" +
            id +
            "/" +
            fname +
            "/" +
            country +
            "/" +
            city +
            "/" +
            address
          ).then((response) => {
            let h = response.data;
            //on error
            if (h != "1") {
              //
              waitLoader.hide();
              //say error
              popup.action = "Notification";
              popup.setTitle("Notification");
              popup.setMessage(
                "Erreur d'enregistrement des données!<br>Veuillez reéssayer."
              );
              popup.setButton("Ok");
              popup.show();
              return;
            }
            //
            //on success
            this.getUserData();
            //
            waitLoader.hide();
            //
            setTimeout(() => {
              Globals.ClientleftMenu.isDataCompleted = true;
            }, 1000);
            //
            popup.action = "Notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Vos informations ont été modifiées avec succès"
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }).catch((error) => {
            console.log(error);
            //
            waitLoader.hide();
            return;
          });
          //
        }
        //
        //else show the phone number confirm
        else {
          Globals.phoneNumberConfirm.phoneNumberConfirmSendCode(tel);
        }
      });
      //
    },

    validate: function () {
      //check if there is at least one change
      let onChange = false;
      //
      let fname = $("#userDataFname")[0].value;
      let tel = this.intTel.getNumber();
      let country = $("#userDataEditCountry")[0].value;
      let city = $("#userDataCity")[0].value;
      let address = $("#userDataAddress")[0].value;
      //
      if (tel != this.userData.tel) onChange = true;
      //
      if (fname != this.userData.fname) onChange = true;
      //
      if (city != this.userData.city) onChange = true;
      //
      if (address != this.userData.home_address) onChange = true;
      //
      if (country != this.userData.country) onChange = true;
      //
      //check if there is a user data change
      if (!onChange) {
        popup.action = "Notification";
        popup.setTitle("Notification");
        popup.setMessage("Aucune modification n'a été faite.");
        popup.setButton("Ok");
        popup.show();
        return;
      }
      //
      //
      //
      let error = false;
      let color = "#F66";
      //tel errors
      if (tel.length >= 1 && tel.length < 8) {
        document.getElementById("userDataTel").style.backgroundColor = color;
        error = true;
      }
      //name errors
      if (fname.length < 2) {
        document.getElementById("userDataFname").style.backgroundColor = color;
        error = true;
      }
      //city errors
      if (city.length >= 1 && city.length < 3) {
        document.getElementById("userDataCity").style.backgroundColor = color;
        error = true;
      }
      //address errors
      if (address.length >= 1 && address.length < 3) {
        document.getElementById("userDataAddress").style.backgroundColor =
          color;
        error = true;
      }
      //
      if (error) return;
      //
      //
      //
      popup.action = "user-set-data";
      popup.setTitle("Question");
      popup.setMessage("Voulez-vous vraiment enregistrer vos nouvelles informations ?");
      popup.setButton("Oui", "Non");
      popup.show();
    },

    resetInput: function (e) {
      let color = "#FFF";
      e.target.style.backgroundColor = color;
    },
  }
};
</script>

<style scoped>
/*----------------------------------
USER DATA EDIT
----------------------------------*/
#userDataEdit {
  max-width: 768px;
  width: 100%;
  margin: auto;
  background-color: #F2FBFF;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  height: max-content;
  padding: 28px;
  color: #111a2b;
  text-align: left;
}

#userDataEdit form input[readonly] {
  background-color: #f8f8f8;
  color: #888;
}

#userDataEdit form select {
  /**/
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  background-color: white;
}

#userDataEdit form button[type=submit] {
  margin-left: 12px;
  margin-top: 32px;
  border: none;
  border-radius: 3px;
  padding: 8px 24px;
  width: auto;
  box-sizing: content-box;
  color: white;
  background-color: #111a2b;
  font-size: 17px;
}

#userDataEdit form button[type=submit]:hover {
  color: white;
  background-color: #1b2842;
}

#userDataEdit form button[type=submit] i {
  color: rgba(123, 255, 0);
  font-size: 16px;
}

#userDataEdit form button[type=submit]:hover {
  background-color: #346;
}


#userDataEdit #userDataTel {
  width: 200px;
}



#backButton {
  font-size: 28px;
  cursor: pointer;
}

#udTitle h4 {
  position: relative;
  top: 3px;
}

#backButton:hover {}




@media only screen and (max-width:512px) {
  #userDataEdit {
    padding: 10px;
  }

  #userDataEdit form input,
  #userDataEdit form select {
    font-size: 12px !important;
  }
}
</style>