import accessRenewOffer from "/src/views/sign/AccessRenewOffer.vue";
import SetDomainEmailPassword from "/src/views/sign/DefineEmailAccountPassword.vue";
//
import LoginMain from '/src/views/sign/LoginMain.vue';
//
import resetPassword from "/src/views/sign/ResetPassword.vue";
import emailConfirmed from '/src/views/sign/EmailConfirmed.vue';
import ConfirmEmail from '/src/views/sign/ConfirmEmail.vue';

const routes = [
    {
        path: "/login",
        component: LoginMain,
    },
    {
        path: "/login-renew-offer",
        component: accessRenewOffer
    },
    {
        path: "/define-email-password",
        component: SetDomainEmailPassword
    },
    {
        path: "/confirm-email",
        component: ConfirmEmail
    },
    {
        path: "/email-confirmed",
        component: emailConfirmed
    },
    {
        path: "/reset-password",
        component: resetPassword
    }
];
//
//
export default routes;