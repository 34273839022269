<template>
    <div id="errorPage">
        <span>404</span>
        <h1>Oooops!</h1>
        <p>Cette page n'existe pas ou est peut être en maintenance.</p>
        <br>
        <a>Retour sur EcoCloud</a>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },

    mounted() {

    },
}
</script>

<style scoped>
body {
    margin: 0;
    padding: 0;
    font-family: "Trebuchet Ms";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-color: #ccc;
}

#errorPage {
    width: 100%;
    max-width: 512px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    text-align: center;
}

#errorPage span {
    font-size: 180px;
}

#errorPage p {
    font-size: 18px;
    color: #555;
}

#errorPage a {
    background-color: #ff6600;
    padding: 14px 20px;
    text-decoration: none;
    font-size: 18px;
    color: white;
    border-radius: 4px;
}
</style>