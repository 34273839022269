<template>
  <main id="manageServices">
    <h1 id="manageServicesTitle">{{ pageName }}</h1>

    <div id="manageServicesTabs">
      <div id="manageServicesTabsBox">
        <router-link to="/client/Subscriptions/Services" class="manageServicesTabItem" @click="selectManageServicesTab">
          <span class="manageServicesTab">
            <i class="bi bi-server"></i>
            <a>Services</a>
            <b>Services</b>
          </span>
        </router-link>

        <router-link to="/client/Subscriptions/Invoices" class="manageServicesTabItem" @click="selectManageServicesTab">
          <span class="manageServicesTab">
            <i class="bi bi-file-earmark-spreadsheet-fill"></i>
            <a>Factures</a>
            <b>Factures</b>
          </span>
        </router-link>
      </div>

      <div id="manageServicesTabsLine">
        <div id="manageServicesTabsCursor" ids="0"></div>
      </div>
    </div>

    <router-view></router-view>

  </main>
</template>


<script>
const $ = require("jquery");


//manageServices component
export default {
  data() {
    return {
    };
  },

  mounted() {
    this.update();
  },

  methods: {
    update() {
      //set cursor tab at correct position
      let obj = $(".manageServicesTabItem.router-link-exact-active")[0];
      let w =
        obj.getBoundingClientRect().right - obj.getBoundingClientRect().left;
      $("#manageServicesTabsCursor").css("width", w + "px");
      let l =
        obj.getBoundingClientRect().left -
        $("#manageServicesTabs")[0].getBoundingClientRect().left;
      $("#manageServicesTabsCursor").css("left", l + "px");

      //on resizing
      $(window).resize(function () {
        try {
          //set cursor tab at correct position
          let objs = document.getElementsByClassName("manageServicesTabItem");
          let ids = document.getElementById("manageServicesTabsCursor").getAttribute("ids");
          let o = objs[ids];
          //
          let w =
            o.getBoundingClientRect().right - o.getBoundingClientRect().left;
          $("#manageServicesTabsCursor").css("width", w + "px");
          let l =
            o.getBoundingClientRect().left -
            $("#manageServicesTabs")[0].getBoundingClientRect().left;
          $("#manageServicesTabsCursor").css("left", l + "px");
        }
        catch (err) {

        }
      });
    },

    selectManageServicesTab(e) {
      let o = e.target.parentElement;
      //set cursor tab at correct position
      let objs = document.getElementsByClassName("manageServicesTabItem");
      let ids = -1;
      for (let i = 0; i < objs.length; i++) {
        if (o == objs[i]) {
          ids = i;
          break;
        }
      }
      $("#manageServicesTabsCursor").attr("ids", ids);
      //
      let w = o.getBoundingClientRect().right - o.getBoundingClientRect().left;
      $("#manageServicesTabsCursor").css("width", w + "px");
      let l =
        o.getBoundingClientRect().left -
        $("#manageServicesTabs")[0].getBoundingClientRect().left;
      $("#manageServicesTabsCursor").css("left", l + "px");
    }
  }
}
</script>

<style scoped>
#manageServices {
  color: black;
  width: 100%;
  position: relative;
  padding: 24px 12px;
}

#manageServicesTitle {
  margin-bottom: -32px;
}

#manageServicesTabs {
  max-width: 840px;
  width: 100%;
  margin: auto;
  margin-top: 64px;
  font-size: 16px;
  text-align: left;
  margin-bottom: 32px;
}

.manageServicesTabItem {
  width: max-content;
  margin-right: 40px;
}

.manageServicesTab {
  width: max-content;
  cursor: pointer;
  color: black;
}

.manageServicesTab:hover {
  color: white;
}

.manageServicesTab i {
  font-size: 20px;
  margin-right: 8px;
}

.manageServicesTab b {
  display: none;
}

#manageServicesTabs .router-link-exact-active {
  color: white;
}

#manageServicesTabs .router-link-exact-active i {
  color: white;
}

#manageServicesTabs .router-link-exact-active a {
  color: white;
}

#manageServicesTabs .router-link-exact-active b {
  color: white;
}

#manageServicesTabsLine {
  margin-top: 8px;
  background-color: #111a2b;
  width: 100%;
  height: 2px;
}

#manageServicesTabsCursor {
  width: 0px;
  background-color: white;
  height: 5px;
  position: relative;
  top: -1px;
  left: 0;
  transition: all 0.25s ease;
  border-radius: 3px;
}

/*---------------------------------
media queries
---------------------------------*/
@media only screen and (max-width:1128px) {
  .manageServicesTab a {
    display: none;
  }

  .manageServicesTab b {
    display: inline;
  }

  .manageServicesTabItem {
    margin-right: 16px;
  }
}

@media only screen and (max-width:600px) {
  .manageServicesTab b {
    display: none;
  }

  .manageServicesTab i {
    margin-left: 16px;
  }

  .manageServicesTabItem {
    margin-right: 8px;
  }
}
</style>