<template>
  <main id="rDom">
    <center>
      <div class="d-flex justify-content-center">
        <!--  -->
        <!--  -->
        <span id="downloadPDF">
          <a>Formulaire d'enregistrement</a>
        </span>
        <!--  -->
        <!--  -->
        <router-link to="/client/domainBfDocsAlt">
          <span id="continueBtn" class="btn">
            <i class="bi bi-arrow-right"></i>
          </span>
        </router-link>
      </div>

      <br>

      <div id="rDomFormAlt">
        <img src="https://client.ecodev.dev/logo-regsitre-bf.png" />
        <h1 style="color:#c60000;">Réservez votre nom de domaine (.bf)</h1>

        <br>

        <h3>Procédure:</h3>

        <br>

        <h5>
          1. Télécharger le formulaire d'enregistrement
          <a href="https://ecodev.dev/downloads/formulaire-nom-domaine-bf-ECODEV.pdf" target="_blank"
            class="fw-bold">ici</a>
        </h5>

        <br>

        <h5>
          2. Remplissez le formulaire, imprimez le, et signez (avec un cachet pour les entreprises)
        </h5>

        <br>

        <h5>
          3. Scannez le formulaire ainsi qu'une pièce d'identité (pour les particuliers) et pour les structures: le
          document IFU, ou RCCM ou Récépissé
        </h5>

        <br>

        <h5>
          4. Puis cliquez sur la flèche en haut à droite pour continuez le processus.
        </h5>

        <br>
        <br>
      </div>

    </center>
  </main>
</template>


<script>
const $ = require("jquery");
// import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
// import functions from "/src/common/js/functions";
// import axios from "axios";
// import popup from "/src/common/js/popup";
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";

//login component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      data: {},
      //
      pageName: "Formulaire de nom de domaine (.bf)",
    };
  },

  mounted() {
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    waitLoader.show();
    //
    window.addEventListener("clientIsReady", this.onStart);
  },

  methods: {
    onStart() {
      this.update();
      waitLoader.hide();
      //
      window.removeEventListener("clientIsReady", this.onStart);
    },

    update() {

    },
    //
  }
};
</script>

<style scoped>
#rDom {
  padding-bottom: 48px;
}




#downloadPDF {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 6px 12px;
  border-radius: 50px;
  background-color: white;
  cursor: default;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
  margin-right: 32px;
}

#downloadPDF i {
  font-size: 24px;
}




.btn {
  margin-right: 32px;
  margin-top: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 50%;
  padding: 6px 12px;
  background-color: white;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
  cursor: pointer;
}

.btn:hover {
  background-color: rgb(199, 46, 46);
}

.btn i {
  font-size: 24px;
  color: black;
}

.btn:hover i {
  color: white;
}




#rDomFormAlt {
  display: inline-block;
  padding: 16px 16px;
  width: 100%;
  max-width: 768px;
  background-color: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
  height: max-content;
}



#rDomFormAlt img {
  width: 128px;
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
.table1 {
  margin-right: 8px;
  border-collapse: collapse;
  /* Collapse cell borders */
  width: 100%;
}


th,
td {
  border: 1px solid black;
  padding: 3px;
  text-align: left;
  font-size: 12px;
}

.odd {
  background-color: #eee;
  font-weight: 600;
  width: 144px;
}

td:not(.odd) {
  font-weight: 600;
  width: 240px;
}
</style>