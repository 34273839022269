<template>
  <div id="userPasswordEdit">
    <div id="udTitle" class="d-flex align-items-center">
      <router-link to="/client/UserData/Infos">
        <i id="backButton" class="bi bi-arrow-left-circle-fill me-3"></i>
      </router-link>
      <h4>Changer de mot de passe</h4>
    </div>

    <br />

    <form v-on:submit.prevent="validate">
      <div>
        <div class="form-floating mb-3">
          <input type="password" id="userPasswordOldPasswd" class="form-control" placeholder="Ancien Mot de passe"
            v-on:input="resetInput" required />
          <label for="userPasswordOldPasswd">Ancien mot de passe</label>
          <label for="userPasswordOldPasswd">Ancien mot de passe</label>
        </div>

        <div class="form-floating mb-3">
          <input type="password" id="userPasswordNewPasswd" class="form-control" placeholder="Nouveau mot de passe"
            v-on:input="resetInput" required />
          <label for="userPasswordNewPasswd">Nouveau mot de passe</label>
          <label for="userPasswordNewPasswd">Nouveau mot de passe</label>
        </div>

        <div class="form-floating" id="userPasswordConfPass">
          <input type="password" id="userPasswordRetypePasswd" class="form-control"
            placeholder="Confirmer le nouveau mot de passe" v-on:input="resetInput" required />
          <label for="userPasswordRetypePasswd">Confirmer le mot de passe</label>
          <label for="userPasswordRetypePasswd">Confirmer. mot de passe</label>
        </div>
      </div>

      <div class="col-12 d-flex justify-content-center">
        <button type="submit">
          CHANGER MOT DE PASSE
          <i class="bi bi-check-lg"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
const $ = require("jquery");
import popup from "/src/common/js/popup";
import waitLoader from "/src/common/js/waitLoader";
import Globals from "/src/common/js/Globals";
import functions from "/src/common/js/functions";
import axios from "axios";
import localforage from "localforage";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";

//UserPasswordEdit component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data: function () {
    return {
      pageName: "Mon mot de passe",
    };
  },

  mounted() {
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    waitLoader.hide();
    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
        waitLoader.hide();
      }, 1);
    },

    update() {
      let $this = this;
      waitLoader.hide();
      //when player want to save new profil data
      $("#popupYes").click(function () {
        //in different popup action
        if (popup.action != "user-set-password") return;
        //
        waitLoader.show();
        //for this popup action
        popup.action = "";
        let id = functions.correctQueryString($this.usersStore.User.account.id);
        let passwd = functions.correctQueryString(
          $("#userPasswordOldPasswd")[0].value
        );
        let newPasswd = functions.correctQueryString(
          $("#userPasswordNewPasswd")[0].value
        );
        //to save new data
        axios
          .post(
            Globals.website_url +
            "php/api.php?query=set-user-password-by-id/" +
            id +
            "/" +
            passwd +
            "/" +
            newPasswd
          )
          .then((response) => {
            let h = response.data;
            console.log(h);
            //on error
            if (!h.success) {
              if (h.error == 0) {
                waitLoader.hide();
                //say error
                popup.action = "Error";
                popup.setTitle("Erreur");
                popup.setMessage(
                  "Ancien Mot de passe incorrecte !<br>Veuillez reéssayer."
                );
                popup.setButton("Ok");
                popup.show();
                return;
                //
              } else {
                waitLoader.hide();
                //say error
                popup.action = "Error";
                popup.setTitle("Erreur");
                popup.setMessage(
                  "Problème d'enregistrement du nouveau mot de passe !<br>Veuillez reéssayer."
                );
                popup.setButton("Ok");
                popup.show();
                return;
              }
            }
            //
            //on success
            $this.usersStore.User.account.password = h.data;
            //save cache data
            localforage
              .setItem("ecocloud/User", JSON.stringify($this.usersStore.User))
              .then(() => {
                waitLoader.hide();
                //
                //reset all input
                $("#userPasswordEdit input").val("");
                //
                //say password changed with succes
                popup.action = "Notification";
                popup.setTitle("Notification");
                popup.setMessage("Votre mot de passe a été changé avec succès");
                popup.setButton("Ok");
                popup.show();
              })
              .catch((err) => {
                console.log(err);
                waitLoader.hide();
                //say error
                popup.action = "Error";
                popup.setTitle("Erreur");
                popup.setMessage(
                  "Veuillez vérifier votre connexion à internet puis reéssayer."
                );
                popup.setButton("Ok");
                popup.show();
                return;
              });
          })
          .catch((error) => {
            waitLoader.hide();
            //
            console.log(error);
          });
      });
    },

    validate: function () {
      let oldPass = $("#userPasswordOldPasswd")[0].value;
      let newPass = $("#userPasswordNewPasswd")[0].value;
      let retypePass = $("#userPasswordRetypePasswd")[0].value;
      //
      let color = "#F66";
      let error = false;
      //check all data for catch error entried data
      if (oldPass.length < 4) {
        $("#userPasswordOldPasswd").css("backgroundColor", color);
        error = true;
      }
      if (newPass.length < 4) {
        $("#userPasswordNewPasswd").css("backgroundColor", color);
        error = true;
      }
      if (retypePass.length < 4) {
        $("#userPasswordRetypePasswd").css("backgroundColor", color);
        error = true;
      }
      //
      if (error) return;
      //on error
      if (newPass != retypePass) {
        popup.action = "Notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Le mot de passe de confirmation est différent du nouveau mot de passe!"
        );
        popup.setButton("Ok");
        popup.show();
        error = true;
      }
      if (oldPass == newPass) {
        popup.action = "Notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "L'ancien mot de passe et le nouveau mot de passe ne doivent pas être identiques!"
        );
        popup.setButton("Ok");
        popup.show();
        error = true;
      }
      // let oldPassword = this.usersStore.User.account.password;
      // if (oldPass != oldPassword) {
      //   popup.action = "Notification";
      //   popup.setTitle("Notification");
      //   popup.setMessage("Ancien mot de passe incorrect!");
      //   popup.setButton("Ok");
      //   popup.show();
      //   error = true;
      // }
      //
      if (error) return;
      //
      popup.action = "user-set-password";
      popup.setTitle("Question");
      popup.setMessage(
        "Voulez-vous vraiment enregistrer ce nouveau mot de passe ?"
      );
      popup.setButton("Oui", "Non");
      popup.show();
    },

    resetInput: function (e) {
      let color = "#FFF";
      e.target.style.backgroundColor = color;
    },
  },
};
</script>

<style scoped>
/*----------------------------------
USER PASSWORD EDIT
----------------------------------*/
#userPasswordEdit {
  max-width: 400px;
  width: 100%;
  margin: auto;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  height: max-content;
  padding: 28px;
  color: #111a2b;
  text-align: left;
}

#userPasswordEdit form button[type="submit"] {
  margin-left: 12px;
  margin-top: 32px;
  border: none;
  border-radius: 3px;
  padding: 8px 24px;
  width: auto;
  box-sizing: content-box;
  color: white;
  background-color: #111a2b;
  font-size: 17px;
}

#userPasswordEdit form button[type="submit"]:hover {
  color: white;
  background-color: #1b2842;
}

#userPasswordEdit form button[type="submit"] i {
  color: rgba(123, 255, 0);
  font-size: 16px;
}

#userPasswordEdit form button[type="submit"]:hover {
  background-color: #346;
}

#userData label:last-child {
  display: none;
}

@media only screen and (max-width: 320px) {
  #userData label:last-child {
    display: block;
  }

  #userData label:nth-last-child(2) {
    display: none;
  }
}

#backButton {
  font-size: 28px;
  cursor: pointer;
}

#udTitle h4 {
  position: relative;
  top: 3px;
}

#backButton:hover {}

@media only screen and (max-width: 512px) {
  #userPasswordEdit {
    padding: 10px;
  }

  #userPasswordEdit form input {
    font-size: 12px !important;
  }
}
</style>
