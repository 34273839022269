import { defineStore } from 'pinia';


export const useUsersStore = defineStore('users', {
    state() {
        return {
            //others data
            //
            //client
            User: {
                account: {},

                theme: "default",

                confirmEmail: "",

                data: {},
            }
        }
    },
});