const fetch = require('node-fetch');


export default {
    async sendNotification(user_subscription_ids, content) {
        const response = await fetch('https://api.onesignal.com/notifications?c=push', {
            method: 'POST',
            headers: {
                'content-Type': 'application/json; charset=utf-8',
                accept: 'application/json',
                'Authorization': 'Basic ZjFhOTYzZTgtOTkxMy00NGZkLWE4ZDAtMDZkMTg3NDZmMzkz',
            },
            body: JSON.stringify({
                app_id: 'db31a6a5-5b74-467c-932f-1aadc86e93e7',
                include_subscription_ids: [user_subscription_ids],
                headings: { en: 'Ecoweb Support' },
                contents: { en: content },
            }),
        });

        const data = await response.json();
        console.log('Notification response:', data);
    },
};