<template>
  <div id="phoneNumberConfirmBack">
    <form id="phoneNumberConfirm" @submit.prevent="phoneNumberConfirmSendCode()" class="text-center">
      <i id="phoneNumberConfirmCloseBtn" class="bi bi-x-lg" @click="phoneNumberConfirmClose()"></i>
      <h3>Veuillez saisir le code recu par telephone</h3>
      <input type="text" id="phoneNumberConfirmCode" placeholder="123456" maxlength="6" pattern="[0-9]{6}"
        @input="phoneNumberConfirmCheckCode()" />
      <br />
      <div class="d-flex justify-content-center w-100">
        <button id="phoneNumberConfirmCodeBtn" enable="true">
          Recevoir un code
        </button>
      </div>
    </form>
  </div>
</template>

<script>
const $ = require("jquery");
import axios from "axios";
import functions from "/src/common/js/functions";
import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
import popup from "/src/common/js/popup";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";
import localforage from "localforage";

export default {
  data() {
    return {
      phoneCode: "",
      tel: "",
    };
  },

  computed: {
    ...mapStores(useUsersStore),
  },

  created() {
    Globals.phoneNumberConfirm = this;
  },

  methods: {
    phoneNumberConfirmOpen() {
      //clear the phoneNumberConfirmInput
      $("#phoneNumberConfirmCode").val("");
      //
      let att = document.createAttribute("open");
      $("#phoneNumberConfirmBack")[0].setAttributeNode(att);
      $("body").css("overflow-y", "hidden");
    },

    phoneNumberConfirmClose() {
      $("#phoneNumberConfirmBack")[0].removeAttribute("open");
      $("body").css("overflow-y", "hidden");
    },

    async phoneNumberConfirmSendCode(tel, email) {
      waitLoader.show();
      this.tel = tel;
      tel = functions.correctPhoneNumberWithoutCQS(tel);
      email = functions.correctQueryString(email);
      //
      try {
        let h = await axios.get(Globals.website_url + "php/api.php?query=check-if-phone-exists/" + tel + "/" + email)
        h = h.data;
        //
        if (h == "1") {
          waitLoader.hide();
          //
          popup.action = "notification";
          popup.setTitle("notification");
          popup.setMessage(
            "Ce numéro de téléphone est deja attribué à un compte."
          );
          popup.setButton("Ok");
          popup.show();
          return;
        }
        this.phoneNumberConfirmCheckCode(email);
        //
        //
        /*
        if (
          $("#phoneNumberConfirmCodeBtn")[0].getAttribute("enable") != "true"
        ) {
          //on wait time
          return;
        }
        //
        //
        $("#phoneNumberConfirmCodeBtn").attr("enable", "false");
        //get userData
        let id = this.usersStore.User.account.id;
        id = functions.correctQueryString(id);
        //create code for user phone confirmation
        let code = functions.syncHttpGet(
          Globals.website_url +
          "php/api.php?query=create-user-phone-code/" +
          id
        );
        this.phoneCode = code;
        //
        $("#phoneNumberConfirmCode").val(code);
        this.phoneNumberConfirmCheckCode();
        
        //get the new phone number
        tel = functions.correctPhoneNumberWithoutCQS(tel);
        //
        let msg = "EcoCloud - Code de confirmation: " + code;
        //open the poneNumberConfirm box
        this.phoneNumberConfirmOpen();
        //
        axios
          .get(
            "https://api.smsbox.io/send-apiv1?to=" +
              tel +
              "&key=e25e-452c&msg=" +
              msg
          )
          .then(() => {
            // console.log(response.data);
            waitLoader.hide();
            //
          })
          .catch((e) => {
            console.log("sms: " + e);
            waitLoader.hide();
            //
            $("#phoneNumberConfirmCodeBtn").attr("enable", "true");
            //
            popup.action = "notification";
            popup.setTitle("notification");
            popup.setMessage(
              "Erreur d'envoi du code de confirmation!<br>Veuillez reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
          });

        //for wait some time before new sending code sms
        setTimeout(function () {
          $("#phoneNumberConfirmCodeBtn").attr("enable", "true");
          waitLoader.hide();
        }, 35000);
        */
        waitLoader.hide();
      }
      catch (e) {
        waitLoader.hide();
        //
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Veuillez vérifier votre connexion à internet puis reéssayer."
        );
        popup.setButton("Ok");
        popup.show();
      }
    },

    async phoneNumberConfirmCheckCode(email) {
      let tel = this.tel;
      /*
      let code = document.getElementById("phoneNumberConfirmCode").value;
      if (code.length < 6) {
        document.getElementById(
          "phoneNumberConfirmCode"
        ).style.backgroundColor = "transparent";
        waitLoader.hide();
        return;
      }
      //check if code is correct
      if (code != this.phoneCode) {
        document.getElementById(
          "phoneNumberConfirmCode"
        ).style.backgroundColor = "#faa";
        waitLoader.hide();
        return;
      }
      document.getElementById("phoneNumberConfirmCode").style.backgroundColor =
        "#afa";
      //
      */
      //on good data
      //
      try {
        waitLoader.show();
        //
        let id = this.usersStore.User.account.id;
        //
        let fname = $("#userDataFname")[0].value;
        //
        let country = $("#userDataEditCountry")[0].value;
        //
        let city = $("#userDataCity")[0].value;
        //
        let address = $("#userDataAddress")[0].value;
        //encode data for good execution of queries
        id = functions.correctQueryString(id);
        tel = functions.correctPhoneNumber(tel);
        fname = functions.correctQueryString(fname);
        country = functions.correctQueryString(country);
        city = functions.correctQueryString(city);
        address = functions.correctQueryString(address);
        // code = functions.correctQueryString(code);
        //sho the phoneNumerConfirm form
        //to save new data
        let h = await axios.post(
          Globals.website_url +
          "php/api.php?query=set-user-data-by-id-with-tel-no-code/" +
          id +
          "/" +
          fname +
          "/" +
          tel +
          "/" +
          country +
          "/" +
          city +
          "/" +
          address
        );
        h = h.data;
        console.log(h);
        //on error
        if (h != "1") {
          waitLoader.hide();
          //
          document.getElementById("phoneNumberConfirmCode").value = "";
          document.getElementById(
            "phoneNumberConfirmCode"
          ).style.backgroundColor = "transparent";
          //say error
          //
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage(
            "Erreur d'enregistrement des données!"
          );
          popup.setButton("Ok");
          popup.show();
          return;
        }
        //close the popup
        // this.phoneNumberConfirmClose();
        //
        waitLoader.hide();
        //
        popup.action = "phone-number-change-success";
        popup.setTitle("Notification");
        popup.setMessage("Vos informations ont été modifiées avec succès");
        popup.setButton("Ok");
        popup.show();
        //
        $("#popupYes").click(async () => {
          if (popup.action != "phone-number-change-success") return;
          //
          //
          //get the new data and save it to the cache
          let fd = new FormData();
          fd.append("query", "get-user-data-by-email/" + email);
          h = await axios.post(Globals.website_url + "php/api.php", fd);
          h = h.data;
          //save new user data in cache
          this.usersStore.User.data = h;
          await localforage.setItem("ecocloud/User", JSON.stringify(this.usersStore.User));
          //
          if (Globals.finalStep) {
            $("#finalStepModalBack").css("display", "none");
            Globals.finalStep = false;
            //refresh the page
            location.reload();
          }
        });
        //
      }
      catch (e) {
        console.log(e);
        waitLoader.hide();
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Erreur d'enregistrement des données!"
        );
        popup.setButton("Ok");
        popup.show();
        //
        return;
      }
    },
  },
};
</script>

<style>
/*---------------------------------
POPUP MESSAGE BOX
---------------------------------*/
#phoneNumberConfirmBack {
  display: none;
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

#phoneNumberConfirmBack[open] {
  display: block;
}

#phoneNumberConfirm {
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  position: fixed;
  z-index: 201;
  max-width: 400px;
  width: 90%;
  float: left;
  background-color: #f2fbff;
  /*border-radius: 16px;*/
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.35);
  padding: 16px;
  transition: all 0.35s ease;
}

#phoneNumberConfirmCloseBtn {
  float: right;
  cursor: pointer;
  color: #aaa;
}

#phoneNumberConfirmCloseBtn:hover {
  color: #c77;
}

#phoneNumberConfirm h3 {
  margin-top: 32px;
  margin-bottom: 24px;
}

#phoneNumberConfirm input {
  border: none;
  border-bottom: solid 2px orange;
  padding: 6px 16px 0px 16px;
  width: 164px;
  font-size: 40px;
  background-color: transparent;
}

#phoneNumberConfirm button {
  padding: 8px 16px;
  font-size: 16px;
  max-width: 100%;
  border: solid 1px #ccc;
  background-color: #f8f8f8;
  color: black;
  margin-top: 16px;
}

#phoneNumberConfirm button:hover {
  background-color: #f0f0f0;
  border-color: #aaa;
}

#phoneNumberConfirm button[enable="false"] {
  color: #aaa;
}

/**/
@media only screen and (max-width: 480px) {
  #phoneNumberConfirm {
    box-shadow: none;
    border: solid 1px #ccc;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}

@media only screen and (max-height: 512px) {
  #phoneNumberConfirm {
    top: 0;
    transform: translateY(0) translateX(-50%);
  }
}
</style>
