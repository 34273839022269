<template>
  <div shown="false">
    <div id="leftMenuHeader">
      <i id="leftMenuCloseBtn" class="bi bi-arrow-left-short" @click="closeLeftMenu"></i>
      <img id="lMLogo" src="@/assets/images/logo-light.png">
    </div>

    <div id="lMMenu">
      <router-link class="lMMenuItem" id="lMHomeBtn" to="/client/home">
        <i class="bi bi-house-fill"></i>
        <b>Accueil</b>
      </router-link>

      <router-link class="lMMenuItem" id="lMHomeBtn" to="/client/UserData/Infos">
        <i class="bi bi-person-circle"></i>
        <b>Profil</b>
      </router-link>

      <router-link v-show="hasEnterprise && isDataCompleted" class="lMMenuItem" to="/client/Offers">
        <i class="bi bi-cart4"></i>
        <b>Commander <br> un Service</b>
      </router-link>

      <router-link v-show="hasEnterprise && isDataCompleted" class="lMMenuItem" id="lMFactureBtn"
        to="/client/Subscriptions/Services">
        <i class="bi bi-layers-fill"></i>
        <b>Services & Factures</b>
      </router-link>

      <router-link v-show="hasEnterprise && isDataCompleted" class="lMMenuItem" id="lMMailBtn" to="/client/domains">
        <i class="bi bi-globe2"></i>
        <b>Domaines</b>
      </router-link>

      <router-link v-show="hasEnterprise && isDataCompleted && hasOneDomainWithMail" class="lMMenuItem" id="lMMailBtn"
        to="/client/email-panel">
        <i class="bi bi-envelope-fill"></i>
        <b>Messagerie</b>
      </router-link>

      <router-link class="lMMenuItem" to="/client/Supports/menu" id="lMFactureBtn">
        <i class="bi bi-question-diamond-fill"></i>
        <b>Support & Assistance</b>
      </router-link>

      <!-- <a v-show="hasEnterprise && isDataCompleted" class="lMMenuItem renewBf" @click="openRenewBfModal()">
        <img src="../../assets/images/logo-regsitre-bf.png" />
        <b>Renouveller <br> votre domaine (.bf)</b>
      </a> -->
    </div>

    <div id="lMBBox">
      <div id="lMWeb">
        <a>Version {{ appVersion }}</a>
      </div>
    </div>

  </div>
</template>

<script>
const $ = require("jquery");
import Globals from "/src/common/js/Globals";
import axios from "axios";
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";
//
import popup from '/src/common/js/popup.js';


//login component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      hasEnterprise: false,
      isDataCompleted: false,
      appVersion: Globals.AppVersion,
      hasOneDomainWithMail: false,
    };
  },

  created() {
    Globals.ClientleftMenu = this;
  },

  mounted() {
    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.update();
    });
    if (Globals.clientMainIsOk) {
      this.update();
    }
  },

  methods: {
    async update() {
      //check if enterprise id exist
      let h = this.usersStore.User.data.enterprise_id;
      this.hasEnterprise = h == null ? false : true;
      this.isDataCompleted = this.usersStore.User.data.creating_step == 1 ? true : false;
      //manage the left menu
      this.manageLeftMenu();

      //initialize an click event for all router-link
      $(".lMMenuItem").click(() => {
        //get id of router-link
        this.closeLeftMenu();
      });

      /*-------------------------------------
      on window resizing for responsive site
      -------------------------------------*/
      $(window).resize(() => {
        //manage the left menu
        this.manageLeftMenu();
      });
      //
      //
      //check if user has one Domain with email
      try {
        const resp = await axios.get(Globals.website_url + "php/api.php?query=check-if-user-has-one-email-domain/" + this.usersStore.User.account.id);
        this.hasOneDomainWithMail = resp.data;
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    },

    closeLeftMenu() {
      //change leftmenu shown state
      $("#leftMenu").attr("shown", "false");
      //and call fucntion manageLeftMenu from leftMenu.js
      this.manageLeftMenu();
    },

    manageLeftMenu() {
      //if screen is big: width is greater than 968px
      if ($(window).width() > 968) {
        $("#leftMenu").css("left", 0);
        $("#client").css("left", "260px");
        $("#header").css("left", "260px");
        $("#leftMenuBack").css("display", "none");
      }

      //or no, screen is small: width is lower or equal to 968px
      if ($(window).width() <= 968) {
        $("#client").css("left", 0);
        $("#header").css("left", 0);
        //
        let shown = $("#leftMenu").attr("shown") == "true" ? true : false;
        if (shown) {
          $("#leftMenu").css("left", 0);
          $("#leftMenuBack").css("display", "block");
          $("body").css("overflow-y", "hidden");
        }
        else {
          $("#leftMenu").css("left", "-300px");
          $("#leftMenuBack").css("display", "none");
          $("body").css("overflow-y", "auto");
        }
      }
    },

    openRenewBfModal() {
      popup.action = "notification";
      popup.setTitle("");
      popup.setMessage(`
<br>
<br>
<br>      
<img src="`+ Globals.website_url + `logo-regsitre-bf.png"/>
<h1 style="color:#c60000;">Renouveller votre nom de domaine (.bf)</h1>

<br>

<h3>Procédure:</h3>
<p>
  <h5>
  1. Télécharger le formulaire de renouvellement <a href="https://ecodev.dev/downloads/formulaire-nom-domaine-bf-ECODEV.pdf" target="_blank" class="fw-bold">ici</a>
  </h5>
</p>

<p>
  <h5>
  2. Remplissez le formulaire, imprimez le, et signez (avec un cachet pour les entreprises)
  </h5>
</p>

<p>
  <h5>
  3. Scannez le formulaire  ainsi qu'une pièce d'identité (pour les particuliers) et pour les structures: le document IFU, ou RCCM ou Récépissé
  </h5>
</p>

<p>
  <h5>
  4. Envoyez les document à l'adresse: bf@ecodev.dev
  </h5>
</p>
`);
      popup.setButton("Compris");
      popup.show();
    }
  }
};
</script>


<style scoped>
#leftMenu {
  z-index: 30;
  max-width: 100%;
  width: 260px;
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: #111a2b !important;
  color: white;
  transition: left 0.25s ease;
}

#leftMenuHeader {
  display: flex;
  width: max-content;
  height: max-content;
  margin: auto;
}

#leftMenuCloseBtn {
  margin-top: 16px;
  margin-right: 16px;
  width: 40px;
  display: none;
  color: #ccc;
  font-size: 32px;
  font-weight: bold;
  line-height: 24px;
  border: solid 2px #ccc;
  padding: 0 2px;
  transition: all 0.25s ease;
  cursor: pointer;
}

#leftMenuCloseBtn:hover {
  color: orange;
  background-color: #152642;
  border-color: #152642;
}

#lMLogo {
  margin-top: 16px;
  width: 180px;
  transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
  margin-bottom: 16px;
}



#lMMenu {
  width: 90%;
  margin: auto;
  height: calc(100% - 156px);
  overflow: auto;
}

.lMMenuItem {
  float: left;
  width: 100%;
  height: 56px;
  border: solid 3px #88F2;
  padding: 4px 4px;
  margin: 5px 0;
  cursor: pointer;
}

.lMMenuItem:hover {
  background-color: #88F2 !important;
}

.lMMenuItem i {
  float: left;
  width: 50px;
  margin-right: 6px;
  font-size: 28px;
  /*background: -webkit-linear-gradient(red, orange);*/
  background-color: white;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lMMenuItem b {
  position: relative;
  top: -2px;
  font-size: 15px;
  font-weight: bold;
  color: white;
  line-height: 15px;
}

#lMHomeBtn b,
#lMCurrencyBtn {
  line-height: 44px;
}

#lMFactureBtn b {
  line-height: 44px;
}

#lMMailBtn b {
  line-height: 44px;
}

.lMMenuItem.router-link-exact-active {
  border-color: #ff6600 !important;
  background-color: #1B334F !important;
}


.renewBf {
  background-color: white;
  border-color: red;
  color: #111a2b;
}

.renewBf:hover {
  background-color: white !important;
  border-color: red !important;
}

.renewBf b {
  color: #05461f;
  font-size: 17px !important;
  font-weight: bold !important;
}

.renewBf img {
  float: left;
  width: 56px;
  margin-right: 6px;
}


/**/
#lMBBox {
  margin-top: 24px;
  text-align: center !important;
}

#lMWeb {
  display: inline-block;
  width: 212px;
  height: 44px;
  color: White;
  font-size: 17px;
  background-color: #111a2b;
}

#lMWeb:before {
  content: '';
  position: absolute;
  width: 216px;
  height: 48px;
  z-index: -1;
  margin-left: -45px;
  margin-top: -2px;
  background: linear-gradient(to bottom, red, orange);
  transition: all 0.25s;
}

#lMWeb a {
  position: relative;
  top: 10px;
}

/*--------------------------------
Media queries for responsive site
--------------------------------*/
@media only screen and (max-width: 968px) {
  #leftMenu {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
  }

  #leftMenuCloseBtn {
    display: block;
    height: 35px !important;
  }

  #lMLogo {
    width: 180px;
  }
}

@media only screen and (max-width:512px) {
  #lMWeb {
    display: none;
  }

  #leftMenu {
    min-width: 280px;
  }

  #lMMenu {
    height: 90vh;
  }
}
</style>