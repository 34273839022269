const $ = require("jquery");



$("#popup").submit(function (e) {
  e.preventDefault();
  //
  $("#popupYes")[0].click();
});




export default {
  action: "",
  visible: false,
  response: "",
  timing: null,

  setTitle(title) {
    $("#popupTitle")[0].innerHTML = title;
  },

  setMessage(message) {
    $("#popupMessage")[0].innerHTML = message;
  },

  setButton(yes, no = "", cancel = "") {
    //yes button
    $("#popupYes")[0].innerHTML = yes;
    //no button
    if (no == "") {
      $("#popupNo").css("display", "none");
    }
    else {
      $("#popupNo")[0].innerHTML = no;
      $("#popupNo").css("display", "block");
    }
    //no button
    if (cancel == "") {
      $("#popupCancel").css("display", "none");
    }
    else {
      $("#popupCancel")[0].innerHTML = cancel;
      $("#popupCancel").css("display", "block");
    }
  },

  show() {
    this.response = "";
    //
    let att = document.createAttribute("open");
    $("#popupBack")[0].setAttributeNode(att);
    $("body").css("overflow-y", "hidden");
    //is already visible
    if (this.visible) {
      clearTimeout(this.timing);
      $("#popup").css("transition", "none");
      $("#popup").css("opacity", 0);
    }
    //
    this.visible = true;
    $("#popup").css("transition", "all 0.35s ease");
    //
    setTimeout(function () {
      $("#popup").css("opacity", 1.0);
      //
      $("#popupYes")[0].focus();
    }, 1);
  },

  hide() {
    if (!this.visible) return;
    //
    $("#popup").css("opacity", 0);
    //
    this.timing = setTimeout(function () {
      $("#popupBack")[0].removeAttribute("open");
      $("body").css("overflow-y", "auto");
      this.visible = false;
    }, 500);
  },

  fireButton(obj) {
    this.hide();
    this.response = obj.getAttribute("val");
  },
};